import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useDownloadExcel } from 'react-export-table-to-excel';
import _ from 'lodash';
import {
  IApiResult,
  ILeftMenu,
  ILoginUser,
  IPageContent,
  IComponentModal,
  IModal,
  ITablePaging,
  IOptionItem,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PageLayout } from '../components/PageLayout';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import { pageContentStore } from '../stores/page.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../stores/componentModal.store';
import { Button, Select, TextInput } from '@mantine/core';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { PbAgGridReact } from '../components/PbAgGridReact';
import MN2302220880 from '../components/MN2302220880/MN2302220880.component';
import * as appUtil from '../utils/app.util';
import * as grpApi from '../apis/grp.api';
import { ValueFormatterParams } from 'ag-grid-community';
import { decode } from 'html-entities';
import { useDisclosure, useResizeObserver } from '@mantine/hooks';
import { cubicBezier, motion } from 'framer-motion';
import { DatePickerInput } from '@mantine/dates';
import moment from 'moment/moment';
import * as nvntApi from '../apis/nvnt.api';
import * as prtnApi from '../apis/prtn.api';

/**
 * 현황 관리 > 현장 재고 이력
 * @constructor
 */
const MN2302220406Page = () => {
  /**
   * 저장소
   */

  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  /**
   * 검색
   */

  // 검색어 입력을 정의함
  const [searchTextInput, setSearchTextInput] = useState<string>('');
  const searchTextInputRef = useRef<string>('');

  // 품목명/품목코드 검색어 입력을 정의함
  const [searchText2Input, setSearchText2Input] = useState<string>('');
  const searchText2InputRef = useRef<string>('');

  // 설치 팀명 검색어 입력을 정의함
  const [searchText3Input, setSearchText3Input] = useState<string>('');
  const searchText3InputRef = useRef<string>('');

  // 기간 날짜 피커를 정의함
  const [searchPeriodDatePicker, setSearchPeriodDatePicker] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const [searchPmStatusSelect, setSearchPmStatusSelect] =
    useState<string>('CMPL');
  const [searchPmStatusSelectItem, setSearchPmStatusSelectItem] = useState<
    IOptionItem[]
  >([]);
  const searchPmStatusSelectRef = useRef<HTMLInputElement>(null);

  // 기간 날짜 피커를 초기화함
  const initSearchPeriodDatePicker = () => {
    // 오늘 날짜를 불러옴
    let today: string = moment().format('YYYY-MM-DD');

    // 한달 전 날짜를 불러옴
    let pastDay: string = moment().subtract(3, 'M').format('YYYY-MM-DD');

    setSearchPeriodDatePicker([
      moment(pastDay, 'YYYY-MM-DD').toDate(),
      moment(today, 'YYYY-MM-DD').toDate(),
    ]);
  };

  // 검색어 입력의 값을 변경함
  const handleSearchInput_onChange = (event: any) => {
    setSearchTextInput(event.target.value);
    searchTextInputRef.current = event.target.value;
  };

  // 검색어 입력에서 키를 입력함
  const handleSearchInput_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 테이블의 데이터를 불러옴
      getSearchResultTableData();
    }
  };

  // 품목명/품목코드 검색어 입력의 값을 변경함
  const handleSearch2Input_onChange = (event: any) => {
    setSearchText2Input(event.target.value);
    searchText2InputRef.current = event.target.value;
  };

  // 품목명/품목코드 검색어 입력에서 키를 입력함
  const handleSearch2Input_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 테이블의 데이터를 불러옴
      getSearchResultTableData();
    }
  };

  // 설치 팀명 검색어 입력의 값을 변경함
  const handleSearch3Input_onChange = (event: any) => {
    setSearchText3Input(event.target.value);
    searchText3InputRef.current = event.target.value;
  };

  // 설치 팀명 검색어 입력에서 키를 입력함
  const handleSearch3Input_onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      // 테이블의 데이터를 불러옴
      getSearchResultTableData();
    }
  };

  // 기간 날짜 피커의 값을 변경함
  const handleSearchPeriodDatePicker_onChange = (event: any) => {
    setSearchPeriodDatePicker(event);
  };

  // PM 검수상태 셀렉트를 초기화함
  const initSearchPmStatusSelect = async () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '검수완료',
        value: 'CMPL',
      },
      {
        label: '미검수',
        value: 'NCMP',
      },
    );

    // 셀렉트 아이템에 적용함
    setSearchPmStatusSelectItem(tmpOptionItem);
  };

  // PM 검수상태 셀렉트에서 선택한 아이템을 변경함
  const handleSearchPmStatusSelect_onChange = (event: any) => {
    setSearchPmStatusSelect(event);
  };

  // 검색 버튼을 클릭함
  const handleSearchButton_onClick = () => {
    // 테이블의 페이지를 변경함
    searchResultTablePagingCurrentPageRef.current = 1;
    setSearchResultTablePaging((pre: ITablePaging) => ({
      ...pre,
      currentPage: searchResultTablePagingCurrentPageRef.current,
    }));

    // 테이블의 데이터를 불러옴
    getSearchResultTableData();
  };

  // 검색 조건 초기화 버튼을 클릭함
  const handleSearchCancelButton_onClick = () => {
    // 설치 그룹명을 초기화함
    setSearchTextInput('');
    searchTextInputRef.current = '';

    // 품목명/품목코드를 초기화함
    setSearchText2Input('');
    searchText2InputRef.current = '';

    // 설치 팀명을 초기화함
    setSearchText3Input('');
    searchText3InputRef.current = '';

    // 기간 날짜 피커를 초기화함
    initSearchPeriodDatePicker();

    // PM 검수상태 셀렉트를 초기화함
    setSearchPmStatusSelect('CMPL');

    // 검색 버튼을 클릭함
    handleSearchButton_onClick();
  };

  /**
   * 검색 결과 테이블
   */

  // 테이블의 크기 감시를 정의함
  const [searchResultTableSizeRef, searchResultTableRect] = useResizeObserver();

  // 테이블을 정의함
  const [searchResultTableColumn, setSearchResultTableColumn] = useState<any>(
    [],
  );
  const [searchResultTable, setSearchResultTable] = useState<any>([]);
  const searchResultTableRef = useRef<any>(null);

  // 테이블 페이징을 정의함
  const [searchResultTablePaging, setSearchResultTablePaging] =
    useState<ITablePaging>({
      totalPage: 1,
      totalRow: 0,
      rowPerPage: 10,
      currentPage: 1,
    });
  const searchResultTablePagingCurrentPageRef = useRef<number>(1);

  // 테이블의 로딩 여부를 정의함
  const [searchResultTableLoading, setSearchResultTableLoading] =
    useState<boolean>(false);

  // 테이블을 초기화함
  const initSearchResultTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'pmNspcStts',
        headerName: 'PM검수상태',
        width: 120,
        suppressSizeToFit: true,
        pinned: 'left',
        valueFormatter: (params: ValueFormatterParams) => {
          return !decode(params.data.pmNspcStts) ? '미검수' : '검수완료';
        },
      },
      {
        field: 'workDate',
        headerName: '일자',
        width: 120,
        suppressSizeToFit: true,
        pinned: 'left',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.workDate).replaceAll('-', '.');
        },
      },
      {
        field: 'itemDtls',
        headerName: '품목명',
        width: 350,
        suppressSizeToFit: true,
        pinned: 'left',
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.itemDtls);
        },
      },
      {
        field: 'itemNmbr',
        headerName: '품목코드',
        width: 120,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.itemNmbr);
        },
      },
      {
        field: 'grpName',
        headerName: '설치그룹',
        width: 150,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.grpName);
        },
      },
      {
        field: 'teamName',
        headerName: '설치팀',
        width: 150,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.teamName);
        },
      },
      {
        field: 'wrhsName',
        headerName: '창고',
        width: 130,
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.wrhsName);
        },
      },
      {
        field: 'fsdrQntt',
        headerName: '현황\n잔여',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
        cellStyle: (params: any) => {
          return { backgroundColor: 'rgba(201,235,253,0.5)' };
        },
      },
      {
        field: 'nvntQntt',
        headerName: '현황\n재고',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
        cellStyle: (params: any) => {
          return { backgroundColor: 'rgba(201,235,253,0.5)' };
        },
      },
      {
        field: 'fltyQntt',
        headerName: '현황\n불량',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
        cellStyle: (params: any) => {
          return { backgroundColor: 'rgba(201,235,253,0.5)' };
        },
      },
      {
        field: 'brknQntt',
        headerName: '현황\n파손',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
        cellStyle: (params: any) => {
          return { backgroundColor: 'rgba(201,235,253,0.5)' };
        },
      },
      {
        field: 'lossQntt',
        headerName: '현황\n분실',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
        cellStyle: (params: any) => {
          return { backgroundColor: 'rgba(201,235,253,0.5)' };
        },
      },
      {
        field: 'crRsdrQntt',
        headerName: '누계\n잔여',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        field: 'crNvntQntt',
        headerName: '누계\n재고',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        field: 'crFltyQntt',
        headerName: '누계\n불량',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        field: 'crBrknQntt',
        headerName: '누계\n파손',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        field: 'crLossQntt',
        headerName: '누계\n분실',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
    );

    // 테이블에 적용함
    setSearchResultTableColumn(tmpColumn);
  };

  // 테이블의 데이터를 불러옴
  const getSearchResultTableData = () => {
    // 검색 결과 테이블의 로딩 여부에 적용함
    setSearchResultTableLoading(true);

    // 검색 기간을 정의함
    let tmpSearchPeriodDatePickerStart: string = '';
    let tmpSearchPeriodDatePickerEnd: string = '';

    // 검색 기간이 없으면 초기값을 적용함
    if (searchPeriodDatePicker[0] === null) {
      tmpSearchPeriodDatePickerStart = moment()
        .subtract(3, 'M')
        .format('YYYYMMDD');
      tmpSearchPeriodDatePickerEnd = moment().format('YYYYMMDD');
    } else {
      tmpSearchPeriodDatePickerStart = moment(searchPeriodDatePicker[0]).format(
        'YYYYMMDD',
      );
      tmpSearchPeriodDatePickerEnd = moment(searchPeriodDatePicker[1]).format(
        'YYYYMMDD',
      );
    }

    nvntApi
      .getNvntHstr({
        grpSearchKeyword: searchTextInputRef.current.trim(),
        teamSearchKeyword: searchText3InputRef.current.trim(),
        itemSearchKeyword: searchText2InputRef.current.trim(),
        pmNspcStts: searchPmStatusSelect,
        startDate: tmpSearchPeriodDatePickerStart,
        endDate: tmpSearchPeriodDatePickerEnd,
        pageSize: searchResultTablePaging.rowPerPage,
        currPageIdx: searchResultTablePagingCurrentPageRef.current,
      })
      .then((data: IApiResult) => {
        if (data.data.list !== undefined) {
          console.log('> data.data.list:', data.data.list);

          // 테이블에 적용함
          setSearchResultTable(data.data.list);

          // 테이블을 페이징함
          setSearchResultTablePaging((pre: ITablePaging) => ({
            ...pre,
            totalPage: data.data.page.totPageCnt,
            totalRow: data.data.page.totItemCnt,
          }));
        }
      })
      .finally(() => {
        // 검색 결과 테이블의 로딩 여부에 적용함
        setSearchResultTableLoading(false);
      });
  };

  // 테이블의 행을 더블클릭함
  const handleSearchResultTable_onRowDoubleClicked = (row: any) => {
    let tmpId: string = 'MN2302220880';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '설치 그룹 상세',
      content: <MN2302220880 id={tmpId} groupId={row.data.grpId} />,
      size: 1000,
    });
  };

  /**
   * 초기화
   */

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 기간 날짜 피커를 초기화함
    initSearchPeriodDatePicker();

    // PM 검수상태 셀렉트를 초기화함
    initSearchPmStatusSelect();

    // 검색 결과 테이블을 초기화함
    initSearchResultTable();

    // 테이블의 데이터를 불러옴
    getSearchResultTableData();

    return () => {};
  }, []);

  // 목록 새로고침이 변경될 때 실행함
  useEffect(() => {
    if (refreshList.length === 0) {
      return;
    }

    if (_.indexOf(refreshList, 'MN2302220808Page') > -1) {
      // 테이블의 페이지를 변경함
      searchResultTablePagingCurrentPageRef.current = 1;
      setSearchResultTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: searchResultTablePagingCurrentPageRef.current,
      }));

      // 테이블의 데이터를 불러옴
      getSearchResultTableData();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220808Page');
    }

    // 검색어 입력의 값을 초기화함
    setSearchTextInput('');
    searchTextInputRef.current = '';

    return () => {};
  }, [refreshList]);

  /**
   * 렌더링
   */

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="space-y-5">
        {/* 검색 폼 그리드 */}
        <PbFormGrid label="검색" cols={2}>
          <PbFormGridCol label="설치 그룹명">
            {/* 검색어 텍스트 입력 */}
            <TextInput
              placeholder="검색어를 입력하세요."
              size="sm"
              onChange={handleSearchInput_onChange}
              onKeyUp={handleSearchInput_onKeyUp}
              value={searchTextInput}
              className="w-full"
            />
          </PbFormGridCol>

          <PbFormGridCol label="품목명 / 품목코드">
            {/* 검색어 텍스트 입력 */}
            <TextInput
              placeholder="검색어를 입력하세요."
              size="sm"
              onChange={handleSearch2Input_onChange}
              onKeyUp={handleSearch2Input_onKeyUp}
              value={searchText2Input}
              className="w-full"
            />

            {/* 버튼 */}
            <div className="flex justify-center items-center space-x-2">
              {/* 검색 버튼 */}
              <Button
                color="indigo"
                radius="xl"
                onClick={handleSearchButton_onClick}
              >
                검색
              </Button>

              {/* 검색 조건 초기화 버튼 */}
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                onClick={handleSearchCancelButton_onClick}
              >
                검색 조건 초기화
              </Button>
            </div>
          </PbFormGridCol>

          <PbFormGridCol label="설치 팀명">
            {/* 검색어 텍스트 입력 */}
            <TextInput
              placeholder="검색어를 입력하세요."
              size="sm"
              onChange={handleSearch3Input_onChange}
              onKeyUp={handleSearch3Input_onKeyUp}
              value={searchText3Input}
              className="w-full"
            />
          </PbFormGridCol>

          <PbFormGridCol label="기간">
            <DatePickerInput
              type="range"
              placeholder="검색 기간을 선택하세요."
              onChange={handleSearchPeriodDatePicker_onChange}
              value={searchPeriodDatePicker}
              valueFormat="YYYY.MM.DD"
              locale="ko"
              firstDayOfWeek={0}
              clearable={true}
              className="w-full"
            />
          </PbFormGridCol>

          <PbFormGridCol label="PM 검수상태">
            <Select
              ref={searchPmStatusSelectRef}
              onChange={handleSearchPmStatusSelect_onChange}
              data={searchPmStatusSelectItem}
              value={searchPmStatusSelect}
              className="w-full"
            />
          </PbFormGridCol>

          <PbFormGridCol label="" isBlank={true}></PbFormGridCol>
        </PbFormGrid>

        {/* 검색 결과 테이블 */}
        <div className="space-y-3 overflow-hidden">
          {/* 조회 결과 개수 */}
          <div className="flex justify-between items-center space-x-5">
            <div></div>

            <div className="flex justify-center items-center space-x-5">
              <div className="flex justify-center items-center">
                <span className="text-base text-gray-600 font-bold">
                  총 {searchResultTablePaging.totalRow}건
                </span>
              </div>
            </div>
          </div>

          {/* 테이블 */}
          <div ref={searchResultTableSizeRef} className="relative w-full h-160">
            <PbAgGridReact
              refs={searchResultTableRef}
              columnDefs={searchResultTableColumn}
              rowData={searchResultTable}
              loading={searchResultTableLoading}
              setLoading={setSearchResultTableLoading}
              defaultColDef={{
                resizable: true,
                sortable: true,
                wrapHeaderText: false,
                autoHeaderHeight: true,
              }}
              rowSelection="single"
              sizeColumnsToFit={false}
              visiblePaging={true}
              paging={{
                totalPage: searchResultTablePaging.totalPage,
                currentPage: searchResultTablePaging.currentPage,
              }}
              onChangePage={(event: any) => {
                // 테이블의 페이지를 변경함
                searchResultTablePagingCurrentPageRef.current = event;
                setSearchResultTablePaging((pre: ITablePaging) => ({
                  ...pre,
                  currentPage: searchResultTablePagingCurrentPageRef.current,
                }));

                // 테이블의 데이터를 불러옴
                getSearchResultTableData();
              }}
              className="ag-grid-multiple-header-name"
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220406Page;
