import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Button, Popover, ScrollArea } from '@mantine/core';
import { useRecoilState } from 'recoil';
import {
  IApiResult,
  IComponentModal,
  IConfig,
  ILeftMenu,
  ILoginUser,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import * as cmnApi from '../../apis/cmn.api';
import { leftMenuStore } from '../../stores/leftMenu.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import {
  addComponentModalStore,
  componentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import { MN2302220202 } from '../MN2302220202';
import { MN2302220204 } from '../MN2302220204';
import { MN2302220502 } from '../MN2302220502';
import { nanoid } from 'nanoid';
import { configStore } from '../../stores/config.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ILeftMenuProps {
  data?: any;
  onClick?: () => void;
}

/**
 * 왼쪽 메뉴
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */

// 마우스가 올라간 메뉴 그룹을 정의함
let valEnteredMenuGroup: string = '';

const LeftMenu = ({ data, onClick }: PropsWithChildren<ILeftMenuProps>) => {
  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 페이지 주소를 정의함
  const location = useLocation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 왼쪽 메뉴 저장소를 정의함
  const [leftMenu, setLeftMenu] = useRecoilState<ILeftMenu[]>(leftMenuStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 환경 설정 저장소를 정의함
  const [config, setConfig] = useRecoilState<IConfig>(configStore);

  // 선택한 메뉴 그룹을 정의함
  const [selectedMenuGroup, setSelectedMenuGroup] = useState<string>('');

  // 마우스가 올라간 메뉴 그룹을 정의함
  const [enteredMenuGroup, setEnteredMenuGroup] = useState<string>('');

  // 홈 버튼을 클릭함
  const handleGotoHome_onClick = () => {
    // 선택한 페이지에 적용함
    setSelectedPage({
      menuId: '',
      fourJpnsDscr: '',
      fourJpnsMenu: '',
      fourKrnDscr: '',
      fourKrnMenu: '',
      fourMenuId: '',
      fourMenuTypeCode: '',
      fourMenuTypeName: '',
      fourMenuCmpnId: '',
      fourNglsDscr: '',
      fourNglsMenu: '',
      fourSortRdr: '',
      fourUrl: '',
      oneJpnsDscr: '',
      oneJpnsMenu: '',
      oneKrnDscr: '',
      oneKrnMenu: '',
      oneMenuId: '',
      oneMenuTypeCode: '',
      oneMenuTypeName: '',
      oneMenuCmpnId: '',
      oneNglsDscr: '',
      oneNglsMenu: '',
      oneSortRdr: '',
      oneUrl: '',
      threeJpnsDscr: '',
      threeJpnsMenu: '',
      threeKrnDscr: '',
      threeKrnMenu: '',
      threeMenuId: '',
      threeMenuTypeCode: '',
      threeMenuTypeName: '',
      threeMenuCmpnId: '',
      threeNglsDscr: '',
      threeNglsMenu: '',
      threeSortRdr: '',
      threeUrl: '',
      twoJpnsDscr: '',
      twoJpnsMenu: '',
      twoKrnDscr: '',
      twoKrnMenu: '',
      twoMenuId: '',
      twoMenuTypeCode: '',
      twoMenuTypeName: '',
      twoMenuCmpnId: '',
      twoNglsDscr: '',
      twoNglsMenu: '',
      twoSortRdr: '',
      twoUrl: '',
    });

    // 선택한 메뉴 그룹을 초기화함
    setSelectedMenuGroup('');

    // navigate('/dashboard');
    window.location.href = '/dashboard';
  };

  // 메뉴 그룹을 클릭함
  const handleMenuGroupClick = (menuId: string) => {
    if (selectedMenuGroup === menuId) {
      // 선택한 메뉴 그룹을 초기화함
      setSelectedMenuGroup('');
    } else {
      // 선택한 메뉴 그룹에 적용함
      setSelectedMenuGroup(menuId);
    }
  };

  // 메뉴 아이템을 클릭함
  const handleMenuClick = (menuId: string) => {
    // 클릭한 페이지를 불러옴
    let tmpClickedPage: ILeftMenu | undefined = _.find(leftMenu, {
      twoMenuId: menuId,
    });

    switch (tmpClickedPage?.twoMenuTypeCode) {
      // 페이지
      case 'P':
        // 해당 페이지로 이동함
        // navigate(tmpClickedPage?.twoUrl);
        window.location.href = tmpClickedPage?.twoUrl;
        break;

      // 모달
      case 'M':
        let tmpAddComponentModal: IComponentModal | null = null;
        let tmpId: string = nanoid();

        // 컴포넌트 모달을 실행함
        switch (tmpClickedPage.twoMenuId) {
          // 영업 관리 > 영업 기회 조회 > 등록
          case 'MN2302220202':
            tmpAddComponentModal = {
              id: tmpId,
              title: '영업 기회 등록',
              content: <MN2302220202 id={tmpId} />,
              size: 1500,
              // button: (
              //   <div className="flex justify-center items-center space-x-2">
              //     <div
              //       onClick={() => setRemoveComponentModal(tmpId)}
              //       className="pb-button secondary"
              //     >
              //       <span>취소</span>
              //     </div>
              //
              //     <div
              //       // onClick={closeComponentModal}
              //       className="pb-button primary"
              //     >
              //       <span>저장</span>
              //     </div>
              //   </div>
              // ),
            };
            break;

          // 영업 관리 > 영업 활동 조회 > 등록
          case 'MN2302220204':
            tmpAddComponentModal = {
              id: tmpId,
              title: '영업 활동 등록',
              content: <MN2302220204 id={tmpId} />,
              size: 1500,
            };
            break;

          // 운영 관리 > CS 조회 > 등록
          case 'MN2302220502':
            tmpAddComponentModal = {
              id: tmpId,
              title: 'CS 등록',
              content: <MN2302220502 id={tmpId} />,
              size: 1500,
            };
            break;

          default:
            break;
        }

        if (tmpAddComponentModal) {
          // 컴포넌트 모달을 추가함
          setAddComponentModal(tmpAddComponentModal);
        }
        break;

      // 링크
      case 'L':
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    // 메뉴 목록을 불러옴
    cmnApi
      .getMenus({ menuDvsnCode: 'WEB', useYn: 'Y' })
      .then((data: IApiResult) => {
        let tmpLeftMenu: ILeftMenu[] = [];

        // 메뉴를 순서대로 정렬함
        let sortedData: any[] = _.orderBy(
          data.data.list,
          ['oneSortRdr', 'twoSortRdr'],
          ['asc', 'asc'],
        );

        if (data.code === '200') {
          // 왼쪽 메뉴에 적용함
          setLeftMenu(sortedData);
        }
      });
    return () => {};
  }, []);

  // 왼쪽 메뉴를 불러왔을 때 실행함
  useEffect(() => {
    if (leftMenu.length === 0) {
      return;
    }

    // 현재 페이지의 주소를 불러옴
    let url: string = location.pathname;

    // 선택한 페이지를 불러옴
    let tmpSelectedPage: ILeftMenu | undefined = _.find(leftMenu, {
      twoUrl: url,
    });

    if (tmpSelectedPage !== undefined) {
      // 선택한 페이지에 적용함
      setSelectedPage(tmpSelectedPage);

      // 선택한 메뉴 그룹에 적용함
      setSelectedMenuGroup(tmpSelectedPage.oneMenuId);
    }

    return () => {};
  }, [leftMenu]);

  return (
    <div className="relative">
      {/* 펼친 메뉴 */}
      {config.menuType && (
        <div className="object-left-appear w-84 relative h-screen z-90">
          {/* 메뉴 접고 펴기 버튼 */}
          <div className="absolute -right-4 top-1/2 -translate-y-1/2 main-menu-bg-color rounded-r-md z-90">
            <div
              onClick={async () => {
                await localStorage.setItem(
                  'menuType',
                  !config.menuType ? 'Y' : 'N',
                );

                setConfig((pre: IConfig) => ({
                  ...pre,
                  menuType: !config.menuType,
                }));
              }}
              className="button-event"
            >
              <div className="px-0.5 py-3 flex justify-center items-center">
                {config.menuType ? (
                  <FontAwesomeIcon
                    icon={['fas', 'caret-left']}
                    className="w-3 h-3 text-white"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={['fas', 'caret-right']}
                    className="w-3 h-3 text-white"
                  />
                )}
              </div>
            </div>
          </div>

          <ScrollArea className="relative h-screen main-menu-bg-color">
            <div>
              <div className="main-menu">
                <div className="navbar-header">
                  <div
                    onClick={handleGotoHome_onClick}
                    className="navbar-brand button-event"
                  >
                    <h1 className="header-logo"></h1>
                  </div>
                </div>

                <div className="main-menu-content">
                  <ul className="navigation navigation-main select-none">
                    {_.uniqBy(leftMenu, 'oneSortRdr').map(
                      (item: any, index: number) => {
                        if (item.oneSortRdr !== '1') {
                          return (
                            <li key={item.oneMenuId} className="nav-item">
                              <a
                                onClick={() =>
                                  handleMenuGroupClick(item.oneMenuId)
                                }
                                className="d-flex"
                              >
                                {item.oneKrnMenu === '영업 관리' && (
                                  // <i className="icon icon-01"></i>
                                  <div className="ml-2 mr-1 w-6 h-6 flex justify-center items-center">
                                    <FontAwesomeIcon
                                      icon={['fas', 'briefcase']}
                                      className="!w-full !h-full text-gray-200"
                                    />
                                  </div>
                                )}
                                {item.oneKrnMenu === '설치 관리' && (
                                  // <i className="icon icon-02"></i>
                                  <div className="ml-2 mr-1 w-6 h-6 flex justify-center items-center">
                                    <FontAwesomeIcon
                                      icon={['fas', 'boxes-packing']}
                                      className="!w-full !h-full text-gray-200"
                                    />
                                  </div>
                                )}
                                {item.oneKrnMenu === '현황관리' && (
                                  // <i className="icon icon-03"></i>
                                  <div className="ml-2 mr-1 w-6 h-6 flex justify-center items-center">
                                    <FontAwesomeIcon
                                      icon={['fas', 'bars-progress']}
                                      className="!w-full !h-full text-gray-200"
                                    />
                                  </div>
                                )}
                                {item.oneKrnMenu === '운영관리' && (
                                  // <i className="icon icon-04"></i>
                                  <div className="ml-2 mr-1 w-6 h-6 flex justify-center items-center">
                                    <FontAwesomeIcon
                                      icon={['fas', 'headset']}
                                      className="!w-full !h-full text-gray-200"
                                    />
                                  </div>
                                )}
                                {item.oneKrnMenu === '파트너사관리' && (
                                  // <i className="icon icon-05"></i>
                                  <div className="ml-2 mr-1 w-6 h-6 flex justify-center items-center">
                                    <FontAwesomeIcon
                                      icon={['fas', 'handshake']}
                                      className="!w-full !h-full text-gray-200"
                                    />
                                  </div>
                                )}
                                {item.oneKrnMenu === 'SAP Tool' && (
                                  <div className="ml-2 mr-1 w-6 h-6 flex justify-center items-center">
                                    <FontAwesomeIcon
                                      icon={['fas', 'diagram-project']}
                                      className="!w-full !h-full text-gray-200"
                                    />
                                  </div>
                                )}
                                {item.oneKrnMenu === '게시판' && (
                                  // <i className="icon icon-06"></i>
                                  <div className="ml-2 mr-1 w-6 h-6 flex justify-center items-center">
                                    <FontAwesomeIcon
                                      icon={['fas', 'chalkboard']}
                                      className="!w-full !h-full text-gray-200"
                                    />
                                  </div>
                                )}
                                {item.oneKrnMenu === '시스템 관리' && (
                                  // <i className="icon icon-07"></i>
                                  <div className="ml-2 mr-1 w-6 h-6 flex justify-center items-center">
                                    <FontAwesomeIcon
                                      icon={['fas', 'gear']}
                                      className="!w-full !h-full text-gray-200"
                                    />
                                  </div>
                                )}
                                <span className="menu-title">
                                  {item.oneKrnMenu}
                                </span>
                              </a>

                              {/* 메뉴 그룹 */}
                              <ul
                                className={
                                  item.oneMenuId === selectedMenuGroup
                                    ? 'active'
                                    : ''
                                }
                              >
                                {/* 하위 메뉴 */}
                                {_.filter(leftMenu, {
                                  oneSortRdr: item.oneSortRdr,
                                }).map((subItem: any, subIndex: number) => {
                                  if (subItem.twoMenuId) {
                                    return (
                                      <li
                                        key={subItem.twoMenuId}
                                        className={
                                          subItem.twoMenuId ===
                                          selectedPage.twoMenuId
                                            ? 'active'
                                            : ''
                                        }
                                      >
                                        <div
                                          key={subItem.twoMenuId}
                                          onClick={() =>
                                            handleMenuClick(subItem.twoMenuId)
                                          }
                                          className="button-event d-flex"
                                        >
                                          <i className="icon-indent"></i>
                                          <span className="menu-title">
                                            {subItem.twoKrnMenu}
                                          </span>

                                          {subItem.twoMenuId ===
                                            selectedPage.twoMenuId && (
                                            <div style={{ paddingTop: '5px' }}>
                                              <i className="icon-active"></i>
                                            </div>
                                          )}
                                        </div>
                                      </li>
                                    );
                                  }
                                })}
                                {/*<li />*/}
                              </ul>
                            </li>
                          );
                        }
                      },
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </ScrollArea>
        </div>
      )}

      {/* 접힌 메뉴 */}
      {!config.menuType && (
        <div className="relative object-left-appear h-screen w-24 main-menu-bg-color z-90">
          {/* 메뉴 접고 펴기 버튼 */}
          <div className="absolute -right-4 top-1/2 -translate-y-1/2 main-menu-bg-color rounded-r-md">
            <div
              onClick={async () => {
                await localStorage.setItem(
                  'menuType',
                  !config.menuType ? 'Y' : 'N',
                );

                setConfig((pre: IConfig) => ({
                  ...pre,
                  menuType: !config.menuType,
                }));
              }}
              className="button-event"
            >
              <div className="px-0.5 py-3 flex justify-center items-center">
                {config.menuType ? (
                  <FontAwesomeIcon
                    icon={['fas', 'caret-left']}
                    className="w-3 h-3 text-white"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={['fas', 'caret-right']}
                    className="w-3 h-3 text-white"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="">
            {/* 로고 */}
            <div className="main-menu">
              <div className="navbar-header !pl-0">
                <div
                  onClick={handleGotoHome_onClick}
                  className="navbar-brand button-event flex justify-center items-center"
                >
                  <div className="header-logo"></div>
                </div>
              </div>
            </div>

            {/* 메뉴 */}
            <div className="space-y-2 py-2">
              {_.uniqBy(leftMenu, 'oneSortRdr').map(
                (item: any, index: number) => {
                  if (item.oneSortRdr !== '1') {
                    return (
                      <div
                        key={index}
                        className="left-menu flex justify-center items-center"
                      >
                        <Popover
                          opened={enteredMenuGroup === item.oneKrnMenu}
                          // onClose={() => setEnteredMenuGroup('')}
                          onClose={() => {
                            // valEnteredMenuGroup = '';
                            setEnteredMenuGroup('');
                          }}
                          position="right"
                          transitionProps={{ transition: 'pop' }}
                          withArrow
                          shadow="xl"
                          zIndex={100}
                        >
                          <Popover.Target>
                            <div
                              onMouseOver={() => {
                                setEnteredMenuGroup(item.oneKrnMenu);
                                // valEnteredMenuGroup = item.oneKrnMenu;
                              }}
                              // onMouseEnter={() => {
                              //   setEnteredMenuGroup(item.oneKrnMenu);
                              //   valEnteredMenuGroup = item.oneKrnMenu;
                              // }}
                              // onMouseLeave={() => setEnteredMenuGroup('')}
                              className={[
                                'p-1 flex justify-center items-center rounded-md bg-indigo-500/10',
                                item.oneMenuId === selectedMenuGroup
                                  ? ' bg-indigo-500/40'
                                  : ' bg-indigo-500/10',
                              ].join(' ')}
                            >
                              {item.oneKrnMenu === '영업 관리' && (
                                // <i className="icon icon-01"></i>
                                <div className="w-6 h-6 flex justify-center items-center">
                                  <FontAwesomeIcon
                                    icon={['fas', 'briefcase']}
                                    className="!w-full !h-full text-gray-200"
                                  />
                                </div>
                              )}
                              {item.oneKrnMenu === '설치 관리' && (
                                // <i className="icon icon-02"></i>
                                <div className="w-6 h-6 flex justify-center items-center">
                                  <FontAwesomeIcon
                                    icon={['fas', 'boxes-packing']}
                                    className="!w-full !h-full text-gray-200"
                                  />
                                </div>
                              )}
                              {item.oneKrnMenu === '현황관리' && (
                                // <i className="icon icon-03"></i>
                                <div className="w-6 h-6 flex justify-center items-center">
                                  <FontAwesomeIcon
                                    icon={['fas', 'bars-progress']}
                                    className="!w-full !h-full text-gray-200"
                                  />
                                </div>
                              )}
                              {item.oneKrnMenu === '운영관리' && (
                                // <i className="icon icon-04"></i>
                                <div className="w-6 h-6 flex justify-center items-center">
                                  <FontAwesomeIcon
                                    icon={['fas', 'headset']}
                                    className="!w-full !h-full text-gray-200"
                                  />
                                </div>
                              )}
                              {item.oneKrnMenu === '파트너사관리' && (
                                // <i className="icon icon-05"></i>
                                <div className="w-6 h-6 flex justify-center items-center">
                                  <FontAwesomeIcon
                                    icon={['fas', 'handshake']}
                                    className="!w-full !h-full text-gray-200"
                                  />
                                </div>
                              )}
                              {item.oneKrnMenu === 'SAP Tool' && (
                                <div className="w-6 h-6 flex justify-center items-center">
                                  <FontAwesomeIcon
                                    icon={['fas', 'diagram-project']}
                                    className="!w-full !h-full text-gray-200"
                                  />
                                </div>
                              )}
                              {item.oneKrnMenu === '게시판' && (
                                // <i className="icon icon-06"></i>
                                <div className="w-6 h-6 flex justify-center items-center">
                                  <FontAwesomeIcon
                                    icon={['fas', 'chalkboard']}
                                    className="!w-full !h-full text-gray-200"
                                  />
                                </div>
                              )}
                              {item.oneKrnMenu === '시스템 관리' && (
                                // <i className="icon icon-07"></i>
                                <div className="w-6 h-6 flex justify-center items-center">
                                  <FontAwesomeIcon
                                    icon={['fas', 'gear']}
                                    className="!w-full !h-full text-gray-200"
                                  />
                                </div>
                              )}
                            </div>
                          </Popover.Target>

                          <Popover.Dropdown>
                            <div className="space-y-3">
                              {/* 메뉴 그룹 */}
                              <div className="pl-2 pr-10 flex justify-start items-center">
                                <span className="text-xl text-gray-700 font-bold">
                                  {item.oneKrnMenu}
                                </span>
                              </div>

                              {/* 하위 메뉴 */}
                              <div className="px-1 py-1 bg-gray-100 rounded-md space-y-2">
                                {_.filter(leftMenu, {
                                  oneSortRdr: item.oneSortRdr,
                                })
                                  .filter(
                                    (subItem: any) =>
                                      subItem.twoMenuId !== '' &&
                                      subItem.twoMenuId !== null,
                                  )
                                  .map((subItem: any, subIndex: number) => (
                                    <div key={subItem.twoMenuId}>
                                      {subIndex > 0 && (
                                        <div
                                          style={{ marginBottom: '0.75rem' }}
                                          className="border-b"
                                        />
                                      )}

                                      <div
                                        onClick={() =>
                                          handleMenuClick(subItem.twoMenuId)
                                        }
                                        className={[
                                          'button-event pr-5',
                                          subItem.twoMenuId ===
                                          selectedPage.twoMenuId
                                            ? 'text-indigo-700'
                                            : '',
                                        ].join(' ')}
                                      >
                                        <span className="text-base">
                                          {subItem.twoKrnMenu}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </Popover.Dropdown>
                        </Popover>
                      </div>
                    );
                  }
                },
              )}
              )
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeftMenu;
