import React, { ReactNode, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

import DevPage from './pages/Dev.page';
import MainPage from './pages/Main.page';
import LoginPage from './pages/user/Login.page';
import NotFound from './pages/common/NotFound';

import './styles/app.style.css';
import './styles/common.style.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import SamplePage from './pages/Sample.page';
import CS_01_01Page from './pages/cs/CS_01_01.page';
import CS_02_03Page from './pages/cs/CS_02_03.page';
import CS_02_01Page from './pages/cs/CS_02_01.page';

import MN2302220201Page from './pages/MN2302220201.page';
import MN2302220203Page from './pages/MN2302220203.page';
import MN2302220205Page from './pages/MN2302220205.page';
import MN2302220301Page from './pages/MN2302220301.page';
import MN2302220302Page from './pages/MN2302220302.page';
import MN2302220303Page from './pages/MN2302220303.page';
import MN2302220304Page from './pages/MN2302220304.page';
import MN2302220401Page from './pages/MN2302220401.page';
import MN2302220402Page from './pages/MN2302220402.page';
import MN2302220403Page from './pages/MN2302220403.page';
import MN2302220501Page from './pages/MN2302220501.page';
import MN2302220503Page from './pages/MN2302220503.page';

import MN2302220601Page from './pages/MN2302220601.page';
import MN2302220701Page from './pages/MN2302220701.page';
import MN2302220702Page from './pages/MN2302220702.page';
import MN2302220703Page from './pages/MN2302220703.page';
import MN2302220801Page from './pages/MN2302220801.page';
import MN2302220802Page from './pages/MN2302220802.page';
import MN2302220803Page from './pages/MN2302220803.page';
import MN2302220804Page from './pages/MN2302220804.page';
import MN2302220805Page from './pages/MN2302220805.page';
import MN2302220806Page from './pages/MN2302220806.page';
import DashboardPage from './pages/Dashboard.page';
import LoginedPage from './pages/user/Logined.page';
import MN2302220603Page from './pages/MN2302220603.page';
import MN2302220305Page from './pages/MN2302220305.page';
import MN2302220404Page from './pages/MN2302220404.page';
import MN2302220306Page from './pages/MN2302220306.page';
import MN2302220307Page from './pages/MN2302220307.page';
import MN2302220807Page from './pages/MN2302220807.page';
import MN2302220808Page from './pages/MN2302220808.page';
import MN2302220405Page from './pages/MN2302220405.page';
import MN2302220406Page from './pages/MN2302220406.page';
import MN2302220407Page from './pages/MN2302220407.page';
import MN2302220308Page from './pages/MN2302220308.page';
import MN2302220901Page from './pages/MN2302220901.page';
import MN2302220902Page from './pages/MN2302220902.page';
import MN2302220903Page from './pages/MN2302220903.page';

// FontAwesome 아이콘을 불러옴
library.add(fab, far, fas);

// 메뉴별 페이지를 정의함
const menuComponent: { menuId: string; component: ReactNode }[] = [
  {
    menuId: 'MN2302220201',
    component: <MN2302220201Page />,
  },
  {
    menuId: 'MN2302220203',
    component: <MN2302220203Page />,
  },
  {
    menuId: 'MN2302220205',
    component: <MN2302220205Page />,
  },
  {
    menuId: 'MN2302220301',
    component: <MN2302220301Page />,
  },
  {
    menuId: 'MN2302220302',
    component: <MN2302220302Page />,
  },
  {
    menuId: 'MN2302220303',
    component: <MN2302220303Page />,
  },
  {
    menuId: 'MN2302220304',
    component: <MN2302220304Page />,
  },
  {
    menuId: 'MN2302220401',
    component: <MN2302220401Page />,
  },
  {
    menuId: 'MN2302220402',
    component: <MN2302220402Page />,
  },
  {
    menuId: 'MN2302220403',
    component: <MN2302220403Page />,
  },
  {
    menuId: 'MN2302220501',
    component: <MN2302220501Page />,
  },
  {
    menuId: 'MN2302220503',
    component: <MN2302220503Page />,
  },
  {
    menuId: 'MN2302220601',
    component: <MN2302220601Page />,
  },
  // {
  //   menuId: 'MN2302220602',
  //   component: <MN2302220602Page />,
  // },
  {
    menuId: 'MN2302220603',
    component: <MN2302220603Page />,
  },
  {
    menuId: 'MN2302220701',
    component: <MN2302220701Page />,
  },
  {
    menuId: 'MN2302220702',
    component: <MN2302220702Page />,
  },
  {
    menuId: 'MN2302220703',
    component: <MN2302220703Page />,
  },
  {
    menuId: 'MN2302220801',
    component: <MN2302220801Page />,
  },
  {
    menuId: 'MN2302220802',
    component: <MN2302220802Page />,
  },
  {
    menuId: 'MN2302220803',
    component: <MN2302220803Page />,
  },
  {
    menuId: 'MN2302220804',
    component: <MN2302220804Page />,
  },
  {
    menuId: 'MN2302220805',
    component: <MN2302220805Page />,
  },
  {
    menuId: 'MN2302220806',
    component: <MN2302220806Page />,
  },
];

function App() {
  useEffect(() => {}, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* 개발 테스트 */}
        <Route path="/dev" element={<DevPage />} />

        {/* 컴포넌트 샘플 */}
        <Route path="/sample" element={<SamplePage />} />

        {/* 메인 */}
        <Route path="/" element={<MainPage />} />

        {/* 대시보드 */}
        <Route path="/dashboard" element={<DashboardPage />} />

        {/* 로그인 */}
        <Route path="/login" element={<LoginPage />} />

        {/* Azure AD 로그인 */}
        <Route path="/logined" element={<LoginedPage />} />

        {/* 마이페이지 */}
        <Route path="/mypage" element={<MainPage />} />

        {/* 영업 관리 > 영업 기회 현황 */}
        <Route
          path="/sales-opportunity-status"
          element={<MN2302220201Page />}
        />

        {/* 영업 관리 > 영업 기회 조회 */}
        <Route
          path="/sales-opportunity-management"
          element={<MN2302220203Page />}
        />

        {/* 영업 관리 > 영업 활동 조회 */}
        <Route path="/sales-work-management" element={<MN2302220205Page />} />

        {/* 설치 관리 > 미등록 작업 관리 */}
        <Route
          path="/unregistered-job-management"
          element={<MN2302220301Page />}
        />

        {/* 설치 관리 > 진행중 작업 관리 */}
        <Route path="/ongoing-job-management" element={<MN2302220302Page />} />

        {/* 설치 관리 > 작업 현황 */}
        <Route path="/install-work-status" element={<MN2302220305Page />} />

        {/* 설치 관리 > 작업 배분 */}
        <Route
          path="/install-work-distribution"
          element={<MN2302220306Page />}
        />

        {/* 설치 관리 > PM검수 대기 현황 */}
        <Route
          path="/pm-inspection-standby-state"
          element={<MN2302220303Page />}
        />

        {/* 설치 관리 > 완료작업 빌링 현황 */}
        <Route
          path="/complete-job-billing-state"
          element={<MN2302220304Page />}
        />

        {/* 설치 관리 > 설치 일정 */}
        <Route path="/setup-schedule" element={<MN2302220307Page />} />

        {/* 설치 관리 > 대시보드 */}
        <Route path="/setup-dashboard" element={<MN2302220308Page />} />

        {/* 현황 관리 > ERP 판매계약 현황 */}
        <Route
          path="/erp-sales-contract-state"
          element={<MN2302220401Page />}
        />

        {/* 현황 관리 > ESL 설치작업 진척 현황 */}
        <Route path="/esl-setup-work-state" element={<MN2302220402Page />} />

        {/* 현황 관리 > 재고 이동 요청 이력 */}
        <Route path="/stock-request-history" element={<MN2302220403Page />} />

        {/* 현황 관리 > 계약 현황 */}
        <Route path="/sales-contract" element={<MN2302220404Page />} />

        {/* 현황 관리 > 현장 재고 현황 */}
        <Route path="/onsite-stock-state" element={<MN2302220405Page />} />

        {/* 현황 관리 > 현장 재고 이력 */}
        <Route path="/onsite-stock-history" element={<MN2302220406Page />} />

        {/* 현황 관리 > 캘린더 테스트 */}
        <Route path="/calendar" element={<MN2302220407Page />} />

        {/* 파트너사 관리 > 협력사 관리 */}
        <Route path="/parther-management" element={<MN2302220601Page />} />

        {/* 파트너사 관리 > 비가동 관리 */}
        <Route path="/downtime-management" element={<MN2302220603Page />} />

        {/* 게시판 > 메뉴얼 */}
        <Route path="/manual-board" element={<MN2302220701Page />} />

        {/* 게시판 > 공지사항 */}
        <Route path="/notice-board" element={<MN2302220702Page />} />

        {/* 게시판 > FAQ */}
        <Route path="/faq-board" element={<MN2302220703Page />} />

        {/* SAP Tool > 납품실적 조회 */}
        <Route path="/delivery-performance" element={<MN2302220901Page />} />

        {/* SAP Tool > 재고이동 요청 조회 */}
        <Route path="/request-stock-transfer" element={<MN2302220902Page />} />

        {/* SAP Tool > 판매오더 동기화 */}
        <Route path="/sales-order-sync" element={<MN2302220903Page />} />

        {/* 시스템 관리 > 메뉴 관리 */}
        <Route path="/menu-management" element={<MN2302220801Page />} />
        <Route
          path="/menu-management-web"
          element={<MN2302220807Page menuType="WEB" />}
        />
        <Route
          path="/menu-management-mobile"
          element={<MN2302220807Page menuType="MBL" />}
        />

        {/* 시스템 관리 > 설치 그룹 관리 */}
        <Route path="/setup-group-management" element={<MN2302220808Page />} />

        {/* 시스템 관리 > 권한 관리 */}
        <Route path="/auth-management" element={<MN2302220802Page />} />

        {/* 시스템 관리 > 권한 그룹 관리 */}
        <Route path="/auth-group-management" element={<MN2302220803Page />} />

        {/* 시스템 관리 > 사용자 관리 */}
        <Route path="/user-management" element={<MN2302220804Page />} />

        {/* 시스템 관리 > 코드 관리 */}
        <Route path="/common-code-management" element={<MN2302220805Page />} />

        {/* 시스템 관리 > 메일 설정 */}
        <Route path="/email-management" element={<MN2302220806Page />} />

        {/* 설치 관리 > 미등록 작업 조회 */}
        <Route
          path="/unregistered-job-management"
          element={<MN2302220302Page />}
        />

        {/* 운영 관리 > CS 현황 */}
        <Route path="/cs0101" element={<CS_01_01Page />} />
        <Route path="/cs-status" element={<MN2302220501Page />} />

        {/* 운영 관리 > CS 조회 */}
        <Route path="/cs0201" element={<CS_02_01Page />} />
        <Route path="/cs-management" element={<MN2302220503Page />} />

        {/* 페이지를 찾을 수 없음 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
