import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import {
  Button,
  Select,
  TextInput,
  Checkbox,
  Radio,
  Progress,
} from '@mantine/core';
import { MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PageLayout } from '../components/PageLayout';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import { pageContentStore } from '../stores/page.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import * as batchApi from '../apis/batch.api';
import { PbAgGridReact } from '../components/PbAgGridReact';
import { decode } from 'html-entities';
import { ValueFormatterParams } from 'ag-grid-community';
import dayjs from 'dayjs';

/**
 * SAP Tool > 판매오더 동기화
 * @constructor
 */
const MN2302220903Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  // 테이블 데이터를 조회함
  const getList = (): void => {
    // 테이블의 로딩 여부에 적용함
    setListTableLoading(true);

    // B1 배치 상태를 조회함
    batchApi.getStatus({}).then((data: IApiResult): void => {
      if (data.data !== undefined) {
        // 테이블 데이터에 적용함
        setTableData(data.data);

        // 테이블을 페이징함
        setListTablePaging((pre: ITablePaging) => ({
          ...pre,
          totalPage: 1,
          totalRow: data.data.length,
        }));
        // setListTablePaging((pre: ITablePaging) => ({
        //   ...pre,
        //   totalPage: data.data.page.totPageCnt,
        //   totalRow: data.data.page.totItemCnt,
        // }));
      }

      // 테이블의 로딩 여부에 적용함
      setListTableLoading(false);
    });
  };

  // 테이블 컬럼을 정의함
  const [tableColumn, setTableColumn] = useState<any>([]);

  // 테이블 데이터를 정의함
  const [tableData, setTableData] = useState<any>([]);

  // 테이블 페이징을 정의함
  const [listTablePaging, setListTablePaging] = useState<ITablePaging>({
    totalPage: 1,
    totalRow: 0,
    rowPerPage: 10,
    currentPage: 1,
  });
  const listTablePagingCurrentPageRef = useRef<number>(1);

  // 테이블의 로딩 여부를 정의함
  const [listTableLoading, setListTableLoading] = useState<boolean>(false);

  // 테이블을 더블클릭함
  const handleListTable_onRowDoubleClicked = (row: any): void => {
    //
  };

  // 테이블의 동기화 버튼을 클릭함
  const handleSyncButtonClick = (params: any): void => {
    console.log('> row params:', params);

    // B1 배치 수동 요청을 처리함
    batchApi
      .postBatchBatchCode({
        batchCode: params.data.batchCode,
      })
      .then((data: IApiResult): void => {
        if (data.code === '200') {
          setModal({
            title: '알림',
            content: '동기화를 요청하였습니다.',
            callback: (): void => {
              // 테이블 데이터를 조회함
              getList();
            },
          });
        } else {
          console.log('> 동기화 Error #1:', data);

          setModal({
            title: '오류',
            content: (
              <>
                <div>동기화에 실패하였습니다.</div>
                <div>({data.message})</div>
              </>
            ),
          });
        }
      })
      .catch((error: any): void => {
        console.log('> 동기화 Error #2:', error.message);

        setModal({
          title: '오류',
          content: (
            <>
              <div>동기화에 실패하였습니다.</div>
              <div>({error.message})</div>
            </>
          ),
        });
      });
  };

  // 테이블을 초기화함
  const initListTable = (): void => {
    // 컬럼을 정의함
    const columns: any[] = [
      {
        field: 'syncButton',
        headerName: 'SAP 동기화',
        width: 110,
        pinned: 'left',
        cellRendererFramework: (params: any) => {
          return (
            <Button
              color="blue"
              // radius="xl"
              // size="md"
              onClick={(): void => {
                handleSyncButtonClick(params);
              }}
              disabled={params.data.batchCode !== 'SLS_RDR'}
            >
              시작
            </Button>
          );
        },
      },
      {
        field: 'batchCode',
        headerName: '배치코드',
        width: 130,
        pinned: 'left',
        valueFormatter: (params: any) => decode(String(params.value || '')),
      },
      {
        field: 'batchStatus',
        headerName: '상태',
        width: 130,
        pinned: 'left',
        valueFormatter: (params: any) => decode(String(params.value || '')),
      },
      {
        field: 'errorMessage',
        headerName: '메시지',
        width: 350,
        valueFormatter: (params: any) => decode(String(params.value || '')),
      },
      {
        field: 'startTime',
        headerName: '시작일시',
        minWidth: 190,
        editable: false,
        valueFormatter: (params: any) =>
          dayjs(String(params.value)).format('YYYY.MM.DD HH:mm:ss'),
      },
      {
        field: 'endTime',
        headerName: '종료일시',
        minWidth: 190,
        flex: 1,
        editable: false,
        valueFormatter: (params: any) =>
          dayjs(String(params.value)).format('YYYY.MM.DD HH:mm:ss'),
      },
    ];

    // 테이블 컬럼에 적용함
    setTableColumn(columns);
  };

  // 한번만 실행함
  useEffect(() => {
    // 테이블을 초기화함
    initListTable();

    // 테이블 데이터를 조회함
    getList();

    return (): void => {
      /* */
    };
  }, []);

  // 목록 새로고침을 감시함
  useEffect((): void => {
    if (refreshList.length === 0) {
      return;
    }

    if (_.indexOf(refreshList, 'MN2302220903Table') > -1) {
      // 테이블의 페이지를 변경함
      listTablePagingCurrentPageRef.current = 1;

      setListTablePaging((pre: ITablePaging) => ({
        ...pre,
        currentPage: listTablePagingCurrentPageRef.current,
      }));

      // 테이블 데이터를 조회함
      getList();

      // 목록 새로고침 목록에서 제거함
      setRemoveRefreshList('MN2302220903Table');
    }
  }, [refreshList]);

  return (
    <PageLayout
      //pageInfoBarLeftArea={<></>}
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="">
        {/* 검색 */}
        <div className="space-y-5">
          {/* 테이블 */}
          <div className="space-y-3">
            <div className="flex justify-between items-center space-x-5">
              <div>
                {/*<Button*/}
                {/*  variant="outline"*/}
                {/*  color="gray"*/}
                {/*  radius="xl"*/}
                {/*  onClick={handlePrtnListTableExportExcelBtn_onClick}*/}
                {/*>*/}
                {/*  엑셀 다운로드*/}
                {/*</Button>*/}
              </div>

              <div className="flex justify-center items-center space-x-5">
                <div className="flex justify-center items-center">
                  <span className="text-base text-gray-600 font-bold">
                    총 {listTablePaging.totalRow}건
                  </span>
                </div>
              </div>
            </div>

            {/* 테이블 */}
            <div className="w-full h-160">
              <PbAgGridReact
                columnDefs={tableColumn}
                rowData={tableData}
                loading={listTableLoading}
                setLoading={setListTableLoading}
                onRowDoubleClicked={handleListTable_onRowDoubleClicked}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                }}
                rowSelection="single"
                sizeColumnsToFit={false}
                visiblePaging={false}
                paging={{
                  totalPage: listTablePaging.totalPage,
                  currentPage: listTablePaging.currentPage,
                }}
                onChangePage={(event: any) => {
                  // 테이블의 페이지를 변경함
                  listTablePagingCurrentPageRef.current = event;
                  setListTablePaging((pre: ITablePaging) => ({
                    ...pre,
                    currentPage: listTablePagingCurrentPageRef.current,
                  }));

                  // 테이블 데이터를 조회함
                  getList();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220903Page;
