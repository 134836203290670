import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import prettyBytes from 'pretty-bytes';
import { AgGridReact } from 'ag-grid-react';
import { ICellRendererParams } from 'ag-grid-community';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import {
  Button,
  Select,
  TextInput,
  Textarea,
  FileInput,
  FileButton,
  Checkbox,
  Group,
  Radio,
  Anchor,
  Overlay,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
} from '../../interfaces/app.interface';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import { PageLayout } from '../../components/PageLayout';
import { PbFormGrid } from '../../components/PbFormGrid';
import { PbFormGridCol } from '../../components/PbFormGridCol';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../../stores/refreshList.store';
import * as ntcbApi from '../../apis/ntcb.api';
import { HtmlEditor } from '../HtmlEditor';
import { useElementSize } from '@mantine/hooks';
import { decode, encode } from 'html-entities';
import PbSection from '../PbSection/PbSection.component';
import * as slsApi from '../../apis/sls.api';
import { notifications } from '@mantine/notifications';
import { overlayStore } from '../../stores/overlay.store';
import { PbAgGridReact } from '../PbAgGridReact';
import PbSelect from '../PbSelect/PbSelect.component';
import { FlexImageFilePreviewList } from '../FlexImageFilePreviewList';
import * as appUtil from '../../utils/app.util';

interface MN2302220712Props {
  id?: string;
  brdId?: string;
  useModifyMode?: boolean;
  data?: any;
  onClick?: () => void;
}

/**
 * 게시판 > 공지사항 > 등록,상세,수정 모달
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220712 = ({
  id = '',
  brdId = '',
  useModifyMode = false,
  data,
  onClick,
}: PropsWithChildren<MN2302220712Props>) => {
  // 언어를 정의함
  const { t } = useTranslation();
  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);
  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);
  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);
  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);
  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);
  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  // 화면 위 검은 화면 저장소를 정의함
  const [visibleOverlay, setVisibleOverlay] =
    useRecoilState<boolean>(overlayStore);

  // 첨부 파일 섹션 > 미리보기 테이블을 정의함
  const [previewFileImages, setPreviewFileImages] = useState<any[]>([]);

  /**************************** API ****************************/

  //공지사항 신규 저장
  const saveNotice = () => {
    // 화면 위의 검은 화면 출력 여부에 적용함
    setVisibleOverlay(true);

    notifications.show({
      title: '알림',
      message: '저장 중입니다.',
    });

    let openCheckBox = notifyOpenCheckbox ? 'Y' : 'N';
    let startDate = '';
    let endDate = '';

    if (openCheckBox === 'Y') {
      startDate = notifyOpenDatePicker[0]
        ? moment(notifyOpenDatePicker[0]).format('YYYYMMDD')
        : '';
      endDate = notifyOpenDatePicker[1]
        ? moment(notifyOpenDatePicker[1]).format('YYYYMMDD')
        : '';
    }

    // 기존 첨부파일 아이디를 불러옴
    let tmpPreFileId: string[] = [];

    // 기존 첨부파일 설명을 불러옴
    let tmpPreFileDescriptions: string[] = [];

    // 기존 첨부파일 정보를 저장함
    fileTable.data
      .filter((item: any) => item.newFile === null)
      .map((item: any) => {
        tmpPreFileId.push(item.fileId);
        tmpPreFileDescriptions.push(item.fileDscr);
      });

    // 신규 첨부파일을 불러옴
    let tmpNewFile: any[] = [];

    // 신규 첨부파일 구분을 불러옴
    let tmpNewSlsfClsfCodeList: string[] = [];

    // 신규 첨부파일 설명을 불러옴
    let tmpNewFileDescriptions: string[] = [];

    // 신규 첨부파일 정보를 저장함
    fileTable.data
      .filter((item: any) => item.newFile !== null)
      .map((item: any) => {
        tmpNewFile.push(item.newFile);
        tmpNewSlsfClsfCodeList.push(item.slsfClsfCode);
        tmpNewFileDescriptions.push(item.fileDscr);
      });

    // 첨부파일의 설명을 확인함
    let checkFileDescriptions: boolean = false;

    fileTable.data.map((item: any) => {
      if (!item.fileDscr.trim()) {
        checkFileDescriptions = true;
      }
    });

    ntcbApi
      .postNotice({
        ntcTypeCode: notifyCategorySelect || '',
        // file: notifyFile,
        fileList: tmpNewFile,
        xpsrNdt: openCheckBox === 'Y' ? endDate : null, // 게시 종료일
        xpsrStrt: openCheckBox === 'Y' ? startDate : null, // 게시 시작일
        xpsrStprYn: notifyOpenCheckbox ? 'Y' : 'N', // 공개기간 설정 여부
        openYn: notifyOpenRadio || '', // 공개 여부
        dtl: encode(notifyContInput.trim() || ''), // 설명
        ttl: notifyTitleInput.trim() || '', // 공지 제목
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 화면 위의 검은 화면 출력 여부에 적용함
          setVisibleOverlay(false);

          notifications.show({
            title: '알림',
            message: '저장하였습니다.',
          });

          // 목록 새로고침
          setAddRefreshList('MN2302220702Table');
          // 컴포넌트 모달을 닫음
          setRemoveComponentModal(id);
        } else {
          // 화면 위의 검은 화면 출력 여부에 적용함
          setVisibleOverlay(false);

          setModal({
            title: '오류',
            content: (
              <>
                <div>저장에 실패하였습니다.</div>
                <div>({data.message})</div>
              </>
            ),
          });
        }
      });
  };

  //공지사항 수정 저장
  const modifyNotice = () => {
    // 화면 위의 검은 화면 출력 여부에 적용함
    setVisibleOverlay(true);

    notifications.show({
      title: '알림',
      message: '저장 중입니다.',
    });

    let openCheckBox = notifyOpenCheckbox ? 'Y' : 'N';
    let startDate = '';
    let endDate = '';
    if (openCheckBox === 'Y') {
      startDate = notifyOpenDatePicker[0]
        ? moment(notifyOpenDatePicker[0]).format('YYYYMMDD')
        : '';
      endDate = notifyOpenDatePicker[1]
        ? moment(notifyOpenDatePicker[1]).format('YYYYMMDD')
        : '';
    }

    let beforeFileId = '';
    let file = notifyFile;
    if (
      notifyFile &&
      notifyFile.fileId &&
      notifyFile.fileId === notifyBeforeFile.fileId
    ) {
      file = null;
      beforeFileId = notifyBeforeFile.fileId;
    }

    // 기존 첨부파일 아이디를 불러옴
    let tmpPreFileId: string[] = [];

    // 기존 첨부파일 설명을 불러옴
    let tmpPreFileDescriptions: string[] = [];

    // 기존 첨부파일 정보를 저장함
    fileTable.data
      .filter((item: any) => item.newFile === null)
      .map((item: any) => {
        tmpPreFileId.push(item.fileId);
        tmpPreFileDescriptions.push(item.fileDscr);
      });

    // 신규 첨부파일을 불러옴
    let tmpNewFile: any[] = [];

    // 신규 첨부파일 구분을 불러옴
    let tmpNewSlsfClsfCodeList: string[] = [];

    // 신규 첨부파일 설명을 불러옴
    let tmpNewFileDescriptions: string[] = [];

    // 신규 첨부파일 정보를 저장함
    fileTable.data
      .filter((item: any) => item.newFile !== null)
      .map((item: any) => {
        tmpNewFile.push(item.newFile);
        tmpNewSlsfClsfCodeList.push(item.slsfClsfCode);
        tmpNewFileDescriptions.push(item.fileDscr);
      });

    // 첨부파일의 설명을 확인함
    let checkFileDescriptions: boolean = false;

    fileTable.data.map((item: any) => {
      if (!item.fileDscr.trim()) {
        checkFileDescriptions = true;
      }
    });

    ntcbApi
      .putNotice({
        brdId: brdId,
        ntcTypeCode: notifyCategorySelect || '',
        // file: file,
        bfFileIdList: tmpPreFileId,
        fileList: tmpNewFile,
        xpsrNdt: openCheckBox === 'Y' ? endDate : null, // 게시 종료일
        xpsrStrt: openCheckBox === 'Y' ? startDate : null, // 게시 시작일
        xpsrStprYn: notifyOpenCheckbox ? 'Y' : 'N', // 공개 기간 설정 여부
        openYn: notifyOpenRadio || '', // 공개 여부
        // dtl: notifyContInput.trim() || '', // 설명
        dtl: encode(notifyContInput.trim() || ''), // 설명
        ttl: notifyTitleInput.trim() || '', // 공지 제목
        bfFileId: beforeFileId, // 이전 파일ID
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 화면 위의 검은 화면 출력 여부에 적용함
          setVisibleOverlay(false);

          notifications.show({
            title: '알림',
            message: '저장하였습니다.',
          });

          // 목록 새로고침
          setAddRefreshList('MN2302220702Table');

          // 컴포넌트 모달을 닫음
          setRemoveComponentModal(id);

          setTimeout(() => {
            // 조회 컴포넌트 모달을 추가함
            let tmpId: string = nanoid();

            // 컴포넌트 모달을 추가함
            setAddComponentModal({
              id: tmpId,
              title: '공지사항 상세',
              content: (
                <MN2302220712 id={tmpId} brdId={brdId} useModifyMode={false} />
              ),
              size: 1500,
            });
          }, 100);

          // setModal({
          //   title: '알림',
          //   content: '저장하였습니다.',
          //   callback: () => {
          //     // 목록 새로고침
          //     setAddRefreshList('MN2302220702Table');
          //     // 컴포넌트 모달을 닫음
          //     setRemoveComponentModal(id);
          //     setTimeout(() => {
          //       // 조회 컴포넌트 모달을 추가함
          //       let tmpId: string = nanoid();
          //       // 컴포넌트 모달을 추가함
          //       setAddComponentModal({
          //         id: tmpId,
          //         title: '공지사항 상세',
          //         content: (
          //           <MN2302220712
          //             id={tmpId}
          //             brdId={brdId}
          //             useModifyMode={false}
          //           />
          //         ),
          //         size: 1500,
          //       });
          //     }, 100);
          //   },
          // });
        } else {
          // 화면 위의 검은 화면 출력 여부에 적용함
          setVisibleOverlay(false);

          setModal({
            title: '오류',
            content: (
              <>
                <div>저장에 실패하였습니다.</div>
                <div>({data.message})</div>
              </>
            ),
          });
        }
      });
  };

  //공지사항 삭제
  const deleteNotice = () => {
    ntcbApi
      .deleteNotice({
        brdId: brdId,
      })
      .then((data: IApiResult) => {
        switch (data.code) {
          case '200':
            // 목록 새로고침을 추가함
            setAddRefreshList('MN2302220702Table');

            // 모달을 출력함
            setModal({
              title: '알림',
              content: '삭제하였습니다.',
              callback: () => setRemoveComponentModal(id),
            });
            break;

          case '':
            console.log('공지사항 삭제 실패 : ' + data);
            // 모달을 출력함
            setModal({
              title: t('common.error'),
              content: '삭제에 실패하였습니다.',
            });
            break;

          default:
            break;
        }
      });
  };

  //공지사항 상세 조회
  const getNotifyDetail = (brdId: string) => {
    ntcbApi
      .getNotice({
        brdId: brdId,
      })
      .then((data: IApiResult) => {
        if (data.data != null) {
          // 공지사항 상세 데이터에 적용함
          setNoticeData(data.data);

          setNotifyTitleInput(data.data.ttl);
          setNotifyCategorySelect(data.data.ntcTypeCode);
          // setNotifyCategorySelect((pre: ISelect) => ({
          //   ...pre,
          //   value: data.data.ntcTypeCode,
          // }));
          setNotifyCategoryInput(data.data.ntcTypeName);
          setNotifyVisitsInput(data.data.vws);
          setNotifyOpenRadio(data.data.openYn);
          setNotifyOpenInput(data.data.openYn === 'Y' ? '공개' : '비공개');
          setNotifyRgsterInput(data.data.rgstId);
          setNotifyContInput(decode(data.data.dtl));
          setNotifyRgstDate(data.data.rgstDate);
          setNotifyModifyDate(data.data.mdfcDate);
          setNotifyOpenCheckbox(data.data.xpsrStprYn === 'Y' ? true : false);
          setNotifyOpenStrtDate(data.data.xpsrStrt);
          setNotifyOpenEndDate(data.data.xpsrNdt);
          setNotifyOpenDatePicker([
            moment(data.data.xpsrStrt, 'YYYY-MM-DD').toDate(),
            moment(data.data.xpsrNdt, 'YYYY-MM-DD').toDate(),
          ]);

          //파일 데이터 설정
          // if (data.data.fileId !== '') {
          //   var fileObj = {
          //     fileId: data.data.fileId,
          //     name: data.data.rgnlFileName,
          //   };
          //   setNotifyFile(fileObj);
          //   setNotifyBeforeFile(fileObj);
          // }
        }
      });
  };

  /**************************** STATE ****************************/
  // 공지사항 상세 데이터를 정의함
  const [noticeData, setNoticeData] = useState<any>(null);

  const [notifyTitleInput, setNotifyTitleInput] = useState<string>(''); //공지 제목
  const notifyTitleInputRef = useRef<HTMLInputElement>(null);

  const [notifyVisitsInput, setNotifyVisitsInput] = useState<string>(''); //조회수
  const [notifyCategorySelect, setNotifyCategorySelect] = useState<string>('');
  const [notifyCategorySelectItem, setNotifyCategorySelectItem] = useState<
    IOptionItem[]
  >([]);

  const [notifyCategoryInput, setNotifyCategoryInput] = useState<string>(''); //공지 유형

  const [notifyOpenRadio, setNotifyOpenRadio] = useState<string>('Y'); //공지 공개 라디오
  const [notifyOpenInput, setNotifyOpenInput] = useState<string>('Y'); //공지 공개 상세 출력

  const [notifyRgsterInput, setNotifyRgsterInput] = useState<string>(''); //공지 등록자
  const [notifyContInput, setNotifyContInput] = useState<string>(''); //공지 내용

  const [notifyRgstDate, setNotifyRgstDate] = useState<string>(''); //등록일
  const [notifyModifyDate, setNotifyModifyDate] = useState<string>(''); //수정일

  const [notifyOpenCheckbox, setNotifyOpenCheckbox] = useState<boolean>(false); // 공개 설정 체크박스
  const [notifyOpenStrtDate, setNotifyOpenStrtDate] = useState<string>(''); // 공개 시작일
  const [notifyOpenEndDate, setNotifyOpenEndDate] = useState<string>(''); // 공개 종료일
  const [notifyOpenDatePicker, setNotifyOpenDatePicker] = useState<
    //공개 시작-종료 데이트피커
    [Date | null, Date | null]
  >([null, null]);

  const [notifyFile, setNotifyFile] = useState<any>(null); //첨부된 파일
  const [notifyBeforeFile, setNotifyBeforeFile] = useState<any>(null); //기존 파일

  // 에디터의 크기를 정의함
  const {
    ref: editorRef,
    width: editorWidth,
    height: editorHeight,
  } = useElementSize();

  // 첨부 파일 섹션 > 테이블 참조를 정의함
  const fileTableRef = useRef<any>(null);

  // 첨부 파일 섹션 > 테이블을 정의함
  const [fileTable, setFileTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  /**************************** ON EVENT LISTENER ****************************/
  //공지사항 제목 이벤트
  const handleNotifyTitleInput_onChange = (event: any) => {
    var sVal = event.target.value;
    var byteLength = getStringByteLength(sVal);
    if (byteLength > 50) {
      setNotifyTitleInput((pre) => pre);
    } else {
      setNotifyTitleInput(sVal);
    }
  };
  //공지사항 공개 라디오 변경 이벤트
  const handleNotifyOpenRadio_onChange = (event: any) => {
    setNotifyOpenRadio(event);
  };
  //공지사항 유형 셀렉트 변경 이벤트
  const handleNotifyCategorySelect_onChange = (event: any) => {
    setNotifyCategorySelect(event);
    // setNotifyCategorySelect({
    //   ...notifyCategorySelect,
    //   value: event,
    // });
  };
  //공지 내용
  const handleNotifyContInput_onChange = (event: any) => {
    setNotifyContInput(event.target.value);
  };
  //공지 공개 기간 설정 체크박스 이벤트
  const handleNotifyOpenCheckbox_onChange = (event: any) => {
    event.target.checked
      ? setNotifyOpenCheckbox(true)
      : setNotifyOpenCheckbox(false);
  };

  //공지 공개 시작 데이트피커 on change
  const handleNotifyOpenDatePicker_onChange = (event: any) => {
    setNotifyOpenDatePicker(event);
  };

  // 첨부 파일 추가
  const handleFileSectionAddButton_onClick3 = (event: any) => {
    setNotifyFile(event);
  };

  // 첨부 파일 섹션 > 추가 버튼을 클릭함
  const handleFileSectionAddButton_onClick = (event: any) => {
    // 첨부 파일 목록을 불러옴
    let tmlFileTableData: any = _.cloneDeep(fileTable.data);

    // 첨부 파일 목록에 새로운 첨부 파일을 추가함
    tmlFileTableData.push({
      fileId: nanoid(),
      fileDntfCode: 'ETC',
      rgnlFileName: event.name,
      pldFileName: '',
      fileSize: event.size,
      fileDscr: '',
      newFile: event,
    });

    // 첨부 파일 섹션 > 테이블에 적용함
    setFileTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmlFileTableData,
    }));
  };

  // 첨부 파일 다운로드
  const fileDownload = () => {
    if (notifyBeforeFile != null) {
      ntcbApi
        .getNncmFile({
          fileId: notifyBeforeFile.fileId,
        })
        .then((data: any) => {
          const url = window.URL.createObjectURL(
            new Blob([data.data], {
              type: data.headers['content-type'],
            }),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', notifyBeforeFile.name);
          document.body.appendChild(link);
          link.click();
        });
    }
  };

  //수정 전환 버튼 이벤트
  const handleModifyBtn_onClick = () => {
    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);
    setTimeout(() => {
      // 수정 컴포넌트 모달을 추가함
      let tmpId: string = nanoid();
      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: '공지사항 수정',
        content: <MN2302220712 id={tmpId} brdId={brdId} useModifyMode={true} />,
        size: 1500,
      });
    }, 100);
  };

  //저장 버튼 on click
  const handleAddSaveBtn_onClick = () => {
    //필수값 여부 확인
    if (!notifyTitleInput.trim()) {
      setModal({
        title: '알림',
        content: '제목을 입력해 주세요.',
      });

      notifyTitleInputRef.current?.focus();
      return;
    }

    if (!notifyContInput.trim()) {
      setModal({
        title: '알림',
        content: '내용을 입력해 주세요.',
      });

      let obj = document.querySelector(
        '.notify-cont-input .ck-content',
      ) as HTMLDivElement;
      obj.focus();

      return;
    }

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              setRemoveModal(true);
              saveNotice();
            }}
          >
            예
          </Button>
        </div>
      ),
    });
  };

  // 수정의 저장 버튼 on click
  const handleModifySaveBtn_onClick = () => {
    //필수값 여부 확인
    if (!notifyTitleInput.trim()) {
      setModal({
        title: '알림',
        content: '제목을 입력해 주세요.',
      });

      notifyTitleInputRef.current?.focus();
      return;
    }

    if (!notifyContInput.trim()) {
      setModal({
        title: '알림',
        content: '내용을 입력해 주세요.',
      });

      let obj = document.querySelector(
        '.notify-cont-input .ck-content',
      ) as HTMLDivElement;
      obj.focus();

      return;
    }

    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              setRemoveModal(true);
              modifyNotice();
            }}
          >
            예
          </Button>
        </div>
      ),
    });
  };

  // 삭제 버튼 on click
  const handleDeleteBtn_onClick = () => {
    setModal({
      title: '확인',
      content: `공지사항 [${notifyTitleInput}]을 삭제하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              setRemoveModal(true);
              deleteNotice();
            }}
          >
            예
          </Button>
        </div>
      ),
    });
  };

  // 에디터의 값이 변경됨
  const handleHetmlEditor_onChange = (event: any) => {
    setNotifyContInput(event);
  };

  // 첨부 파일 섹션 > 전체 삭제 버튼을 클릭함
  const handleFileSectionDeleteButton_onClick = () => {
    setModal({
      title: '확인',
      content: '전체 첨부파일을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 첨부 파일 섹션 > 테이블에 적용함
            setFileTable((pre: { column: any; data: any }) => ({
              ...pre,
              data: [],
            }));
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 첨부 파일 섹션 > 선택 삭제 버튼을 클릭함
  const handleFileSectionSelectedDeleteButton_onClick = () => {
    // 삭제할 첨부 파일 아이디 목록을 정의함
    let selectedFileId: string[] = [];

    // 삭제할 첨부 파일 아이디 목록을 불러옴
    fileTableRef.current.api.getSelectedRows().map((item: any) => {
      selectedFileId.push(item.fileId);
    });

    if (selectedFileId.length === 0) {
      setModal({
        title: '알림',
        content: '삭제할 첨부 파일을 체크하여 선택하세요.',
      });

      return;
    }

    setModal({
      title: '확인',
      content: '선택하신 첨부파일을 삭제하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            setRemoveModal(true);

            // 첨부 파일 목록에서 삭제할 첨부 파일을 적용한 후 최종 목록을 정의함
            let tmlFileTableData: any = fileTable.data.filter(
              (item: any) => !_.includes(selectedFileId, item.fileId),
            );

            // 첨부 파일 섹션 > 테이블에 적용함
            setFileTable((pre: { column: any; data: any }) => ({
              ...pre,
              data: tmlFileTableData,
            }));
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 첨부 파일 섹션 > 추가 버튼을 클릭함
  const handleFileSectionAddButton_onClick2 = (event: any) => {
    // 첨부 파일 목록을 불러옴
    let tmlFileTableData: any = _.cloneDeep(fileTable.data);

    // 첨부 파일 목록에 새로운 첨부 파일을 추가함
    tmlFileTableData.push({
      fileId: nanoid(),
      slsfClsfCode: 'ETC',
      slsfClsfName: '기타',
      rgnlFileName: event.name,
      pldFileName: '',
      fileSize: event.size,
      fileDscr: '',
      newFile: event,
    });

    // 첨부 파일 섹션 > 테이블에 적용함
    setFileTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: tmlFileTableData,
    }));
  };

  // 첨부파일 다운로드를 클릭함
  const handleFileDownload_onClick = (fileId: string, rgnlFileName: string) => {
    ntcbApi
      .getNncmFile({
        fileId: fileId,
      })
      .then((data: any) => {
        const url = window.URL.createObjectURL(
          new Blob([data.data], {
            type: data.headers['content-type'],
          }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', rgnlFileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  /**************************** INIT ****************************/
  //공지사항 유형 셀렉트 초기화
  const initNotifyCategorySelect = () => {
    let tmpOptionItem: IOptionItem[] = [];
    tmpOptionItem.push(
      {
        label: '소식',
        value: 'NEWS',
      },
      {
        label: '네트워크',
        value: 'NTWR',
      },
      {
        label: 'ESL',
        value: 'ESL',
      },
      {
        label: '기타',
        value: 'ETC',
      },
    );

    setNotifyCategorySelect('NEWS');
    setNotifyCategorySelectItem(tmpOptionItem);
    // setNotifyCategorySelect({
    //   value: 'NEWS',
    //   item: tmpOptionItem,
    // });
  };

  // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블을 초기화함
  const initFileTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 추가, 수정 상태일 때만 체크박스를 추가함
    if (!brdId || useModifyMode) {
      // 컬럼에 추가함
      tmpColumn.push({
        field: 'checkbox',
        width: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
      });
    }

    // 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '파일명',
        field: 'rgnlFileName',
        width: 600,
        editable: false,
        cellRenderer: (params: any) => (
          <div className="h-full flex justify-start items-center">
            <div>
              <span
                onClick={() =>
                  handleFileDownload_onClick(
                    params.data.fileId,
                    params.data.rgnlFileName,
                  )
                }
                title={`다운로드: ${params.data.rgnlFileName}`}
                className="cursor-pointer hover:underline"
              >
                {params.value}
              </span>
            </div>
          </div>
        ),
        // cellRenderer: (params: any) => (
        //   <div className="h-full flex justify-start items-center space-x-3">
        //     <div
        //       onClick={() =>
        //         handleFileDownload_onClick(
        //           params.data.fileId,
        //           params.data.rgnlFileName,
        //         )
        //       }
        //       title={`다운로드: ${params.data.rgnlFileName}`}
        //       className="button-event px-0.5 py-0.5 flex justify-center items-center"
        //     >
        //       <FontAwesomeIcon
        //         icon={['fas', 'download']}
        //         className="w-5 h-5 text-indigo-600"
        //       />
        //     </div>
        //
        //     <div>
        //       <span>{params.value}</span>
        //     </div>
        //   </div>
        // ),
      },
      {
        headerName: '용량',
        field: 'fileSize',
        width: 150,
        editable: false,
        valueFormatter: (params: any) => prettyBytes(_.toNumber(params.value)),
        type: 'rightAligned',
      },
      // { headerName: '설명', field: 'fileDscr', width: 360 },
    );

    // 첨부 파일 섹션 > 테이블에 적용함
    setFileTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블 데이터를 불러옴
  const getFileTableData = () => {
    noticeData.fileList.map((item: any, index: number) => {
      item['newFile'] = null;
    });

    // 첨부 파일 섹션 > 테이블에 적용함
    setFileTable((pre: { column: any; data: any }) => ({
      ...pre,
      data: noticeData.fileList,
    }));

    // 이미지 미리보기용 첨부파일 목록을 정의함
    let tmpFileList: any[] = [];

    // 이미지 미리보기용 첨부파일 목록을 생성함
    noticeData.fileList.map((fileItem: any) => {
      tmpFileList.push({
        id: fileItem.fileId,
        originalFileName: fileItem.rgnlFileName,
        saveFileName: fileItem.pldFileName,
        size: fileItem.fileSize,
      });
    });

    // 이미지 미리보기용 첨부파일 목록에 적용함
    setPreviewFileImages(tmpFileList);
  };

  /**************************** USE EFFECT ****************************/
  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    initNotifyCategorySelect(); //유형 셀렉트 초기화
    if (brdId) {
      getNotifyDetail(brdId);
    }

    // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블을 초기화함
    initFileTable();

    return () => {};
  }, []);

  // 에디터의 크기가 변경될 때 실행함
  useEffect(() => {
    // console.log('> editorWidth:', editorWidth, editorHeight);

    return () => {};
  }, [editorWidth]);

  // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (fileTable.column.length === 0 || noticeData === null) {
      return;
    }

    // 첨부 파일 폼 그리드 > 첨부 파일 > 테이블 데이터를 불러옴
    getFileTableData();

    return () => {};
  }, [fileTable.column, noticeData]);

  /**************************** 기타 Function ****************************/
  const getStringByteLength = (str: string) => {
    return str
      .split('')
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);
  };

  return (
    <div className="space-y-7">
      {/* 컴포넌트 모달 내용 */}
      <div className="space-y-5">
        {/* 공지 정보 폼 그리드 */}
        <PbFormGrid label="공지 정보" cols={2}>
          <PbFormGridCol label="제목" colSpan={2} withAsterisk={true}>
            <TextInput
              ref={notifyTitleInputRef}
              placeholder="제목을 입력하세요."
              onChange={handleNotifyTitleInput_onChange}
              readOnly={brdId && !useModifyMode ? true : false}
              variant={brdId && !useModifyMode ? 'unstyled' : 'default'}
              value={decode(notifyTitleInput)}
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol label="공지 유형">
            {brdId && !useModifyMode && (
              <TextInput
                placeholder=""
                value={notifyCategoryInput}
                readOnly={brdId && !useModifyMode ? true : false}
                variant={brdId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            )}
            {(!brdId || useModifyMode) && (
              <Select
                onChange={handleNotifyCategorySelect_onChange}
                data={notifyCategorySelectItem}
                value={notifyCategorySelect}
                className="w-full"
              />
            )}
          </PbFormGridCol>
          {brdId && !useModifyMode && (
            <PbFormGridCol label="조회수">
              <TextInput
                placeholder=""
                value={appUtil.getFormatNumber(notifyVisitsInput || 0)}
                readOnly={true}
                variant="unstyled"
                className="w-full"
              />
            </PbFormGridCol>
          )}
          <PbFormGridCol label="등록자">
            <TextInput
              placeholder=""
              value={!brdId ? loginUser.name : notifyRgsterInput}
              readOnly={true}
              variant="unstyled"
              className="w-full"
            />
          </PbFormGridCol>
          <PbFormGridCol
            label="공개 상태"
            colSpan={brdId && !useModifyMode ? 1 : 2}
          >
            {brdId && !useModifyMode && (
              <TextInput
                placeholder=""
                value={notifyOpenInput}
                readOnly={true}
                variant="unstyled"
                className="w-full"
              />
            )}
            {brdId && useModifyMode && (
              <Radio.Group
                size="sm"
                name="prtnCategory"
                onChange={handleNotifyOpenRadio_onChange}
                value={notifyOpenRadio}
                className="w-full"
              >
                <Group>
                  <Radio
                    value="Y"
                    label="공개"
                    disabled={brdId && !useModifyMode ? true : false}
                  />
                  <Radio
                    value="N"
                    label="비공개"
                    disabled={brdId && !useModifyMode ? true : false}
                  />
                </Group>
              </Radio.Group>
            )}
          </PbFormGridCol>
          {/*<PbFormGridCol label="내용" colSpan={2} className="h-96">*/}
          {/*  /!* 텍스트 영역 *!/*/}
          {/*  <Textarea*/}
          {/*    minRows={15}*/}
          {/*    className="w-full"*/}
          {/*    value={notifyContInput}*/}
          {/*    onChange={handleNotifyContInput_onChange}*/}
          {/*    readOnly={brdId && !useModifyMode ? true : false}*/}
          {/*    variant={brdId && !useModifyMode ? 'unstyled' : 'default'}*/}
          {/*  />*/}
          {/*</PbFormGridCol>*/}
          {/* 설명 */}
          {/* 등록 */}
          {!brdId && (
            <PbFormGridCol label="내용" colSpan={2} className="h-124">
              {/* 에디터 */}
              <div ref={editorRef} className="notify-cont-input w-full h-full">
                <HtmlEditor
                  data={notifyContInput}
                  // width={editorWidth}
                  width={1056}
                  height={375}
                  callback={handleHetmlEditor_onChange}
                />
              </div>
            </PbFormGridCol>
          )}

          {/* 설명 */}
          {/* 수정 */}
          {brdId && useModifyMode && (
            <PbFormGridCol label="내용" colSpan={2} className="h-124">
              {/* 에디터 */}
              <div ref={editorRef} className="w-full h-full">
                {editorWidth > 0 && notifyContInput && (
                  <HtmlEditor
                    data={notifyContInput}
                    width={editorWidth}
                    height={375}
                    callback={handleHetmlEditor_onChange}
                  />
                )}
              </div>
            </PbFormGridCol>
          )}

          {/* 설명 */}
          {/* 조회 */}
          {brdId && !useModifyMode && (
            <PbFormGridCol label="내용" colSpan={2} className="">
              {/* HTML 뷰어 */}
              <div dangerouslySetInnerHTML={{ __html: notifyContInput }} />
            </PbFormGridCol>
          )}

          {/*<PbFormGridCol label="첨부파일" colSpan={2}>*/}
          {/*  {(!brdId || useModifyMode) && (*/}
          {/*    <FileButton onChange={handleFileSectionAddButton_onClick}>*/}
          {/*      {(props: any) => (*/}
          {/*        <Button color="indigo" radius="xl" {...props}>*/}
          {/*          파일 선택*/}
          {/*        </Button>*/}
          {/*      )}*/}
          {/*    </FileButton>*/}
          {/*  )}*/}
          {/*  {brdId && !useModifyMode && (*/}
          {/*    <>*/}
          {/*      <div*/}
          {/*        onClick={() => fileDownload()}*/}
          {/*        className="button-event flex justify-center items-center"*/}
          {/*      >*/}
          {/*        <FontAwesomeIcon*/}
          {/*          icon={['far', 'file']}*/}
          {/*          className="w-5 h-5 text-gray-500"*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    </>*/}
          {/*  )}*/}
          {/*  <TextInput*/}
          {/*    placeholder=""*/}
          {/*    value={!notifyFile ? '' : notifyFile.name}*/}
          {/*    readOnly={true}*/}
          {/*    className="w-full"*/}
          {/*  />*/}
          {/*</PbFormGridCol>*/}

          {/* 첨부 파일 섹션 */}
          {/* 조회 상태 */}
          {brdId && !useModifyMode && (
            <PbFormGridCol label="첨부 파일" colSpan={2} className="">
              <div className="w-full space-y-3">
                {/* 테이블 */}
                <div className="w-full h-80">
                  <PbAgGridReact
                    refs={fileTableRef}
                    columnDefs={fileTable.column}
                    rowData={fileTable.data}
                    defaultColDef={{
                      resizable: true,
                      sortable: true,
                      wrapHeaderText: false,
                      autoHeaderHeight: true,
                    }}
                    rowSelection="single"
                    sizeColumnsToFit={true}
                  />
                </div>

                {/* 첨부파일 미리보기 */}
                <div className="flex justify-start items-center space-x-1">
                  <FlexImageFilePreviewList data={previewFileImages} />
                </div>
              </div>

              {/* 테이블 */}
              {/*<div className="w-full h-80">*/}
              {/*  <AgGridReact*/}
              {/*    columnDefs={fileTable.column}*/}
              {/*    rowData={fileTable.data}*/}
              {/*    defaultColDef={{*/}
              {/*      resizable: true,*/}
              {/*      sortable: true,*/}
              {/*      editable: false,*/}
              {/*      wrapHeaderText: false,*/}
              {/*      autoHeaderHeight: true,*/}
              {/*      suppressSizeToFit: true,*/}
              {/*    }}*/}
              {/*    rowSelection="single"*/}
              {/*    overlayNoRowsTemplate="데이터가 없습니다."*/}
              {/*    className="ag-theme-alpine"*/}
              {/*  ></AgGridReact>*/}
              {/*</div>*/}
            </PbFormGridCol>
          )}
          {/* 첨부 파일 섹션 */}
          {/* 추가, 수정 상태 */}
          {(!brdId || useModifyMode) && (
            <PbFormGridCol label="첨부 파일" colSpan={2} className="">
              <div className="w-full space-y-3">
                {/* 버튼 */}
                <div className="flex justify-end items-center space-x-2">
                  {/* 버튼 */}
                  <Button
                    variant="outline"
                    color="pink"
                    radius="xl"
                    onClick={handleFileSectionDeleteButton_onClick}
                  >
                    전체 삭제
                  </Button>

                  {/* 버튼 */}
                  <Button
                    color="pink"
                    radius="xl"
                    onClick={handleFileSectionSelectedDeleteButton_onClick}
                  >
                    선택 삭제
                  </Button>

                  {/* 버튼 */}
                  <FileButton onChange={handleFileSectionAddButton_onClick}>
                    {(props: any) => (
                      <Button color="indigo" radius="xl" {...props}>
                        추가
                      </Button>
                    )}
                  </FileButton>
                </div>

                {/* 테이블 */}
                <div className="w-full h-80">
                  <PbAgGridReact
                    refs={fileTableRef}
                    columnDefs={fileTable.column}
                    rowData={fileTable.data}
                    defaultColDef={{
                      resizable: true,
                      sortable: true,
                      editable: true,
                      wrapHeaderText: false,
                      autoHeaderHeight: true,
                    }}
                    rowSelection="multiple"
                    sizeColumnsToFit={true}
                  />
                </div>
              </div>
            </PbFormGridCol>
          )}

          {brdId && (
            <>
              <PbFormGridCol label="등록일">
                <TextInput
                  placeholder=""
                  value={notifyRgstDate}
                  readOnly={true}
                  variant="unstyled"
                  className="w-full"
                />
              </PbFormGridCol>
              <PbFormGridCol label="수정일">
                <TextInput
                  placeholder=""
                  value={notifyModifyDate}
                  readOnly={true}
                  variant="unstyled"
                  className="w-full"
                />
              </PbFormGridCol>
            </>
          )}
          <PbFormGridCol label="공개 기간" colSpan={2}>
            {brdId && !useModifyMode && (
              <TextInput
                placeholder=""
                value={`${notifyOpenStrtDate} ~ ${notifyOpenEndDate}`}
                readOnly={true}
                variant={brdId && !useModifyMode ? 'unstyled' : 'default'}
                className="w-full"
              />
            )}
            {(!brdId || useModifyMode) && (
              <>
                <Checkbox
                  label="공개 기간 설정"
                  checked={notifyOpenCheckbox}
                  onChange={handleNotifyOpenCheckbox_onChange}
                />
                {notifyOpenCheckbox && (
                  <>
                    <DatePickerInput
                      type="range"
                      placeholder="시작 일자 - 종료 일자"
                      onChange={handleNotifyOpenDatePicker_onChange}
                      value={notifyOpenDatePicker}
                      valueFormat="YYYY.MM.DD"
                      locale="ko"
                      firstDayOfWeek={0}
                      clearable={true}
                      className="w-1/3"
                    />
                  </>
                )}
              </>
            )}
          </PbFormGridCol>
        </PbFormGrid>
      </div>

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area">
        {!brdId && (
          <>
            <div>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => setRemoveComponentModal(id)}
              >
                닫기
              </Button>
            </div>
            <div>
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleAddSaveBtn_onClick}
              >
                저장
              </Button>
            </div>
          </>
        )}
        {brdId && !useModifyMode && (
          <>
            <div>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => setRemoveComponentModal(id)}
              >
                닫기
              </Button>
            </div>
            <div>
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleModifyBtn_onClick}
              >
                수정 전환
              </Button>
            </div>
          </>
        )}
        {brdId && useModifyMode && (
          <>
            <div>
              <Button
                variant="outline"
                color="gray"
                radius="xl"
                size="md"
                onClick={() => setRemoveComponentModal(id)}
              >
                닫기
              </Button>
            </div>
            <div>
              <Button
                variant="outline"
                color="pink"
                radius="xl"
                size="md"
                onClick={handleDeleteBtn_onClick}
              >
                삭제
              </Button>
            </div>
            <div>
              <Button
                color="indigo"
                radius="xl"
                size="md"
                onClick={handleModifySaveBtn_onClick}
              >
                저장
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MN2302220712;
