import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PageLayout } from '../components/PageLayout';
import * as sttnApi from '../apis/sttn.api';
import * as workApi from '../apis/work.api';
import * as cmnApi from '../apis/cmn.api';
import moment from 'moment';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import { pageContentStore } from '../stores/page.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../stores/componentModal.store';
import { Button, Select, Tabs } from '@mantine/core';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { PbAgGridReact } from '../components/PbAgGridReact';
import { Calendar, DatePickerInput } from '@mantine/dates';
import * as appUtil from '../utils/app.util';
import PbSection from '../components/PbSection/PbSection.component';
import { MN2302220603_SelectWorkType } from '../components/MN2302220603_SelectWorkType';
import { decode } from 'html-entities';
import { getAsgnPrtn, postUnasgn } from '../apis/work.api';
import * as csApi from '../apis/cs.api';
import { notifications } from '@mantine/notifications';
import { nanoid } from 'nanoid';
import { ValueFormatterParams } from 'ag-grid-community';

/**
 * 파트너사 관리 > 비가동 관리
 * @constructor
 */
const MN2302220603Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 목록 새로고침 저장소를 정의함
  const [refreshList, setRefreshList] =
    useRecoilState<string[]>(refreshListStore);

  // 삭제할 목록 새로고침 저장소를 정의함
  const [removeRefreshList, setRemoveRefreshList] = useRecoilState<string>(
    removeRefreshListStore,
  );

  // 검색 폼 그리드 > 조회기간 > 년월일 피커를 정의함
  const [searchDatePicker, setSearchDatePicker] = useState<
    [Date | null, Date | null]
  >([null, null]);

  // 검색 폼 그리드 > 계약명 셀렉트를 정의함
  const [contractSelect, setContractSelect] = useState<string>('');
  const [contractSelectItem, setContractSelectItem] = useState<IOptionItem[]>(
    [],
  );

  // 검색 폼 그리드 > 담당 PM 셀렉트를 정의함
  const [pmNameSelect, setPmNameSelect] = useState<string>('');
  const [pmNameSelectItem, setPmNameSelectItem] = useState<IOptionItem[]>([]);

  // 선택한 탭을 정의함
  const [selectedTab, setSelectedTab] = useState<string>('NTWR_CNST');

  // 조회기간의 일자들을 정의함
  const [rangeDate, setRangeDate] = useState<Date[]>([]);

  // 가동일 테이블을 정의함
  const [networkDowntimeTable, setNetworkDowntimeTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });
  const [eslDowntimeTable, setEslDowntimeTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 가동일 관리 달력의 선택한 날짜를 정의함
  const [selectedCalendarDay, setSelectedCalendarDay] = useState<Date>(
    new Date(),
  );

  // 가동일 관리 테이블을 정의함
  const [manageDowntimeTable, setManageDowntimeTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 가동일 관리 테이블의 변경 전 데이터를 정의함
  const [manageDowntimeTableBeforeData, setManageDowntimeTableBeforeData] =
    useState<any[]>([]);

  // 검색 폼 그리드 > 조회기간 > 년월일 피커를 변경함
  const handleSearchDatePicker_onChange = (event: any) => {
    setSearchDatePicker(event);
  };

  // 검색 폼 그리드 > 계약명 셀렉트에서 선택한 아이템을 변경함
  const handleContractSelect_onChange = (event: any) => {
    setContractSelect(event);
  };

  // 검색 폼 그리드 > 담당 PM 셀렉트에서 선택한 아이템을 변경함
  const handlePmNameSelect_onChange = (event: any) => {
    setPmNameSelect(event);
  };

  // 가동일 관리 테이블의 행을 클릭함
  const handleManageDowntimeTable_onRowDoubleClicked = (row: any) => {
    // 배정구분이 작업일반일 경우에는 다른 배정구부으로 변경할 수 없음
    if (row.data.prtnCode === 'WORK_GNRL') {
      return;
    }

    let tmpId: string = 'MN2302220603_SelectWorkType';

    // 컴포넌트 모달을 추가함
    setAddComponentModal({
      id: tmpId,
      title: '작업 배정구분 선택',
      content: (
        <MN2302220603_SelectWorkType
          id={tmpId}
          selectedData={row.data}
          callback={(result: any) => {
            let tmpData: any[] = _.cloneDeep(manageDowntimeTable.data);

            tmpData.map((item: any) => {
              if (
                item.splrId === row.data.splrId &&
                item.teamid === row.data.teamid &&
                item.cntrNmbr === row.data.cntrNmbr &&
                item.shipToCode === row.data.shipToCode
              ) {
                item.prtnWorkName = result.label;
                item.prtnCode = result.value;
              }
            });

            // 테이블에 적용함
            setManageDowntimeTable((pre: { column: any; data: any }) => ({
              ...pre,
              data: tmpData,
            }));
            // setManageDowntimeTable((pre: { column: any; data: any }) => ({
            //   ...pre,
            //   data: [
            //     ...pre.data.slice(0, tmpIndex),
            //     {
            //       ...pre.data[tmpIndex],
            //       prtnWorkName: result.label,
            //       prtnCode: result.value,
            //     },
            //     ...pre.data.slice(tmpIndex + 1),
            //   ],
            // }));
          }}
        />
      ),
      size: 350,
    });
  };

  // 가동일 관리 달력에서 날짜를 선택함
  const handleSelectedCalendarDay_onClick = (date: Date) => {
    if (manageDowntimeTable.data !== manageDowntimeTableBeforeData) {
      setModal({
        title: '다른 날짜로 변경하시겠습니까?',
        content: '현재 날짜에 변경된 내용이 있습니다.',
        useOkayButton: false,
        useCancelButton: true,
        cancelButtonLabel: '아니오',
        button: (
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              setRemoveModal(true);
              setSelectedCalendarDay(date);
            }}
          >
            예
          </Button>
        ),
      });
    } else {
      setSelectedCalendarDay(date);
    }
  };

  // 가동일 관리의 저장 버튼을 클릭함
  const handleManageDowntimeSaveButton_onClick = () => {
    // 변경된 데이터를 정의함
    let tmpChangedData: any[] = [];

    // 변경된 데이터를 찾음
    manageDowntimeTable.data.map((item: any, index: number) => {
      let tmpFind: any = _.find(manageDowntimeTableBeforeData, {
        splrId: item.splrId,
        teamid: item.teamid,
        cntrNmbr: item.cntrNmbr,
        shipToCode: item.shipToCode,
      });

      // 변경된 데이터를 기억함
      if (!_.isEqual(item.prtnCode, tmpFind.prtnCode)) {
        tmpChangedData.push({
          prtnCode: item.prtnCode,
          splrId: item.splrId,
          teamId: item.teamid,
          prtnDate: moment(selectedCalendarDay).format('YYYY-MM-DD'),
        });
      }
    });

    // 변경된 데이터가 없으면 저장하지 않음
    if (tmpChangedData.length === 0) {
      setModal({
        title: '알림',
        content: '변경된 데이터가 없습니다.',
      });

      return;
    }

    // 변경된 데이터를 저장함
    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <Button
          color="indigo"
          radius="xl"
          size="md"
          onClick={() => {
            workApi
              .postUnasgn({ workUnAsgnRsnPramList: tmpChangedData })
              .then((data: IApiResult) => {
                if (data.code === '200') {
                  notifications.show({
                    title: '알림',
                    message: '저장하였습니다.',
                  });

                  // 수정한 데이터를 기억함
                  setManageDowntimeTableBeforeData(manageDowntimeTable.data);

                  // 가동일 테이블 데이터를 불러옴
                  getDowntimeTableData();
                } else {
                  notifications.show({
                    color: 'red',
                    title: '알림',
                    message: '저장에 실패하였습니다.',
                  });
                }
              })
              .catch((error: any) => {
                notifications.show({
                  color: 'red',
                  title: '알림',
                  message: `저장에 실패하였습니다.(${error.message})`,
                });
              });

            setRemoveModal(true);
          }}
        >
          예
        </Button>
      ),
    });
  };

  // 검색 폼 그리드 > 계약명 셀렉트의 아이템을 초기화함
  const initContractSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    // tmpOptionItem.push({
    //   label: '전체',
    //   value: '',
    // });

    cmnApi.getCntrs({}).then((data: IApiResult) => {
      data.data.list.map((item: any, index: number) => {
        tmpOptionItem.push({
          label: decode(item.cntrName),
          value: decode(item.cntrNmbr),
        });
      });

      setContractSelectItem(tmpOptionItem);
    });
  };

  // 검색 폼 그리드 > 담당 PM 셀렉트의 아이템을 초기화함
  const initPmNameSelect = () => {
    let tmpOptionItem: IOptionItem[] = [];

    tmpOptionItem.push(
      {
        label: '전체',
        value: 'ALL',
      },
      {
        label: '담당자 없음',
        value: '',
      },
    );

    // 판매 계약 담당PM 목록을 불러옴
    sttnApi.getSttnErpSearchPm({}).then((data: IApiResult) => {
      _.sortBy(data.data.list, ['sortRdr']).map((item: any, index: number) => {
        tmpOptionItem.push({
          label: item.name,
          value: item.userId,
        });
      });

      // 검색 폼 그리드 > 담당 PM 셀렉트의 아이템에 적용함
      setPmNameSelectItem(tmpOptionItem);
    });
  };

  // 가동일 테이블을 초기화함
  const initDowntimeTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'rowCategory',
        headerName: '구분',
        width: 200,
        pinned: 'left',
        suppressSizeToFit: true,
        valueFormatter: (params: ValueFormatterParams) => {
          return decode(params.data.rowCategory);
        },
      },
      {
        field: 'sum',
        headerName: '합계',
        width: 80,
        pinned: 'left',
        suppressSizeToFit: true,
        // valueFormatter: (params: any) =>
        //   appUtil.getFormatNumber(params.value || 0),
        // cellStyle: { 'background-color': 'rgb(243 244 246)' },
      },
    );

    // 생성된 날짜만큼 컬럼을 추가함
    rangeDate.map((item: any) => {
      tmpColumn.push({
        field: `day${moment(item).format('YYYYMMDD')}`,
        headerName: `${moment(item).format('MM')}\n${moment(item).format(
          'DD',
        )}`,
        width: 70,
        suppressSizeToFit: true,
        type: 'rightAligned',
        // valueFormatter: (params: any) =>
        //   appUtil.getFormatNumber(params.value || 0),
        cellStyle: (params: any) => {
          // 설치 미배정 행의 값이 0보다 크면 배경색을 변경함
          if (
            params.data.rowType === 'install-unassigned' &&
            (params.value || 0) > 0
          ) {
            return { backgroundColor: 'rgb(253 230 138)' };
          }
        },
      });
    });

    // 테이블에 적용함
    setNetworkDowntimeTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
    setEslDowntimeTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 가동일 관리 테이블을 초기화함
  const initManageDowntimeTable = () => {
    // 컬럼을 생성함
    let tmpColumn: any = [];

    // 컬럼에 추가함
    tmpColumn.push(
      {
        field: 'no',
        headerName: 'NO',
        width: 80,
        suppressSizeToFit: true,
        type: 'rightAligned',
        valueFormatter: (params: any) =>
          appUtil.getFormatNumber(params.value || 0),
      },
      {
        field: 'prtnName',
        headerName: '협력사',
        width: 150,
        suppressSizeToFit: true,
        valueFormatter: (params: any) => decode(params.value),
      },
      {
        field: 'teamName',
        headerName: '팀',
        width: 130,
        suppressSizeToFit: true,
        valueFormatter: (params: any) => decode(params.value),
      },
      {
        field: 'cntrName',
        headerName: '계약명',
        valueFormatter: (params: any) => decode(params.value),
      },
      {
        field: 'shipToCode',
        headerName: 'Shop명',
        width: 150,
        suppressSizeToFit: true,
        valueFormatter: (params: any) => decode(params.value),
      },
      {
        field: 'pmName',
        headerName: '담당PM',
        width: 100,
        suppressSizeToFit: true,
      },
      {
        field: 'prtnWorkName',
        headerName: '배정구분',
        width: 110,
        suppressSizeToFit: true,
        valueFormatter: (params: any) => params.value || '미지정',
      },
    );

    // 테이블에 적용함
    setManageDowntimeTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  // 가동일 테이블 데이터를 불러옴
  const getDowntimeTableData = () => {
    ['NTWR_CNST', 'ESL_NSTL'].map((typeItem: string) => {
      // 기본 행을 정의함
      let tmpData: any[] = [];

      // 기본 행을 추가함
      tmpData.push(
        {
          rowType: 'sum-order',
          rowCategory: '주문 총 건수',
          sum: 0,
        },
        {
          rowType: 'install-unassigned',
          rowCategory: '설치 미배정',
          sum: 0,
        },
        {
          rowType: 'enable-partner',
          rowCategory: '협력사 가용',
          sum: 0,
        },
      );

      console.log('> pmNameSelect:', pmNameSelect);
      console.log('> contractSelect:', contractSelect);

      workApi
        .getAsgn({
          splrTypeCode: typeItem,
          srchStrtDate: moment(searchDatePicker[0]).format('YYYY-MM-DD'),
          srchEndDate: moment(searchDatePicker[1]).format('YYYY-MM-DD'),
          pmId: pmNameSelect,
          cntrNmbr: contractSelect,
        })
        .then((data: IApiResult) => {
          if (data.data.workAsgnRangeDayList !== undefined) {
            // 불러온 데이터를 테이블에 맞게 가공하여 적용함
            data.data.workAsgnRangeDayList.map((item: any) => {
              // 주문 총 건수 행
              tmpData
                .filter((filterItem: any) => filterItem.rowType === 'sum-order')
                .map((subItem: any) => {
                  // 합계
                  // subItem.sum = item.ttlDcmnCnt;
                  subItem.sum += +(item.nedSgnCnt || 0);

                  // 날짜별
                  subItem[
                    'day' +
                      moment(item.xpctDate, 'YYYY-MM-DD').format('YYYYMMDD')
                  ] = +(item.nedSgnCnt || 0);
                });

              // 설치 미배정 행
              tmpData
                .filter(
                  (filterItem: any) =>
                    filterItem.rowType === 'install-unassigned',
                )
                .map((subItem: any) => {
                  // 합계
                  subItem.sum += +item.notSgnCnt || 0;

                  // 날짜별
                  subItem[
                    'day' +
                      moment(item.xpctDate, 'YYYY-MM-DD').format('YYYYMMDD')
                  ] = item.notSgnCnt || 0;
                });

              // 협력사 가용 행
              tmpData
                .filter(
                  (filterItem: any) => filterItem.rowType === 'enable-partner',
                )
                .map((subItem: any) => {
                  // 합계
                  subItem.sum = '';

                  // 날짜별
                  subItem[
                    'day' +
                      moment(item.xpctDate, 'YYYY-MM-DD').format('YYYYMMDD')
                  ] = item.sgnPsblTeamCnt || 0;
                });
            });

            console.log('> pmNameSelect.2:', pmNameSelect);
            console.log('> contractSelect.2:', contractSelect);

            // 협력사 행을 생성함
            workApi
              .getAsgnPrtn({
                splrTypeCode: typeItem,
                srchStrtDate: moment(searchDatePicker[0]).format('YYYY-MM-DD'),
                srchEndDate: moment(searchDatePicker[1]).format('YYYY-MM-DD'),
                pmId: pmNameSelect,
                cntrNmbr: contractSelect,
              })
              .then((subData: IApiResult) => {
                if (subData.data.workAsgnDcmnList !== undefined) {
                  // 협력사, 팀 컬럼만 추출함
                  let tmpPickColumn: any[] = _.map(
                    subData.data.workAsgnDcmnList,
                    (item: any) =>
                      _.pick(item, [
                        'splrId',
                        'teamid',
                        'prtnName',
                        'teamName',
                      ]),
                  );

                  // 협력사, 팀 컬럼을 비교하여 중복을 제거함
                  let tmpUniqData: any[] = _.uniqWith(tmpPickColumn, _.isEqual);

                  // 데이터를 정렬함
                  let tmpResultData: any[] = _.sortBy(tmpUniqData, [
                    'prtnName',
                    'teamName',
                  ]);

                  // 협력사, 팀 목록을 생성함
                  tmpResultData.map((subItem: any) => {
                    let tmpRow: any = {
                      rowType: '',
                      rowCategory: `${subItem.prtnName} / ${subItem.teamName}`,
                      sum: '',
                    };

                    // 협력사/팀의 작업일반 개수를 정의함
                    let tmpWorkGnrlCount: number = 0;

                    // 협력사/팀의 작업일반 개수의 기본값을 적용함
                    tmpRow['sum'] = 0;

                    // 불러온 데이터에 협력사, 팀의 값이 일치하는 데이터를 찾아서 해당 날짜에 해당하는 컬럼에 값을 넣음
                    subData.data.workAsgnDcmnList
                      .filter(
                        (filterItem: any) =>
                          filterItem.splrId === subItem.splrId &&
                          filterItem.teamid === subItem.teamid,
                      )
                      .map((dayItem: any) => {
                        // 날짜가 포함되어 있으면 해당 날짜에 해당하는 컬럼에 값을 넣음
                        if (
                          dayItem.prtnDate !== null &&
                          dayItem.prtnDate !== ''
                        ) {
                          let tmpIcon: string = '';

                          // 배정구분에 따라 아이콘을 할당함
                          switch (dayItem.prtnCode) {
                            // 작업일반
                            case 'WORK_GNRL':
                              tmpIcon = 'O';

                              // 협력사/팀의 작업일반 개수를 누적함
                              tmpWorkGnrlCount += 1;
                              break;

                            // 작업추가
                            // 작업불가
                            // 휴일
                            case 'WORK_ADED':
                            case 'WORK_NBL':
                            case 'DAY_OF':
                              tmpIcon = 'X';
                              break;

                            default:
                              break;
                          }

                          tmpRow['sum'] = tmpWorkGnrlCount;

                          tmpRow[
                            'day' +
                              moment(dayItem.prtnDate, 'YYYY-MM-DD').format(
                                'YYYYMMDD',
                              )
                          ] = tmpIcon;
                        }
                      });

                    // 협력사, 팀 목록을 추가함
                    tmpData.push(tmpRow);
                  });

                  switch (typeItem) {
                    // 네트워크
                    case 'NTWR_CNST':
                      // 테이블에 적용함
                      setNetworkDowntimeTable(
                        (pre: { column: ITableColumn[]; data: any }) => ({
                          ...pre,
                          data: tmpData,
                        }),
                      );
                      break;

                    // ESL
                    case 'ESL_NSTL':
                      // 테이블에 적용함
                      setEslDowntimeTable(
                        (pre: { column: ITableColumn[]; data: any }) => ({
                          ...pre,
                          data: tmpData,
                        }),
                      );
                      break;

                    default:
                      break;
                  }
                }
              });
          }
        });
    });
  };

  // 가동일 관리 테이블 데이터를 불러옴
  const getManageDowntimeTableData = () => {
    workApi
      .getAsgnDayPrtn({
        splrTypeCode: selectedTab,
        prtnDate: moment(selectedCalendarDay).format('YYYYMMDD'),
      })
      .then((data: IApiResult) => {
        // 테이블에 적용함
        setManageDowntimeTable(
          (pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            data: data.data.workAsgnDcmnList,
          }),
        );

        // 가동일 관리 테이블의 변경 전 데이터에 적용함
        setManageDowntimeTableBeforeData(data.data.workAsgnDcmnList);
      });
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 오늘은 기준으로 한달 전/후의 날짜를 불러옴
    let tmpStartDate: Date = moment(Date.now()).add(-1, 'months').toDate();
    let tmpEndDate: Date = moment(Date.now()).add(1, 'months').toDate();

    // 검색 폼 그리드 > 조회기간 > 년월일 피커에 적용함
    setSearchDatePicker([tmpStartDate, tmpEndDate]);

    return () => {};
  }, []);

  // 로그인 정보가 준비될 때 실행함
  useEffect(() => {
    if (loginUser.id === '') {
      return;
    }

    // 검색 폼 그리드 > 담당PM > 셀렉트의 아이템을 초기화함
    initPmNameSelect();

    // 검색 폼 그리드 > 계약명 > 셀렉트의 아이템을 초기화함
    initContractSelect();

    // 가동일 테이블을 초기화함
    initDowntimeTable();

    // 가동일 관리 테이블을 초기화함
    initManageDowntimeTable();

    return () => {};
  }, [loginUser.id]);

  // 가동일 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (networkDowntimeTable.column.length === 0) {
      return;
    }

    // 가동일 테이블 데이터를 불러옴
    getDowntimeTableData();

    return () => {};
  }, [networkDowntimeTable.column, eslDowntimeTable.column]);

  // 조회기간이 변경될 때 실행함
  useEffect(() => {
    if (searchDatePicker[0] === null || searchDatePicker[1] === null) {
      return;
    }

    let tmpDates = appUtil.getDatesBetween(
      new Date(moment(searchDatePicker[0]).format('YYYY-MM-DD')),
      new Date(moment(searchDatePicker[1]).format('YYYY-MM-DD')),
    );

    // 조회기간의 일자들에 적용함
    setRangeDate(tmpDates);

    return () => {};
  }, [searchDatePicker]);

  // 담당PM, 계약명이 변경될 때 실행함
  useEffect(() => {
    // 가동일 테이블 데이터를 불러옴
    getDowntimeTableData();

    // // 가동일 관리 테이블 데이터를 불러옴
    // getManageDowntimeTableData();

    return () => {};
  }, [pmNameSelect, contractSelect]);

  // 선택한 탭이 변경될 때 실행함
  useEffect(() => {
    // 가동일 관리 테이블 데이터를 불러옴
    getManageDowntimeTableData();

    return () => {};
  }, [selectedTab]);

  // 가동일 날짜 목록이 변경될 때 실행함
  useEffect(() => {
    // 가동일 테이블을 초기화함
    initDowntimeTable();

    // 가동일 관리 테이블 데이터를 불러옴
    getManageDowntimeTableData();

    return () => {};
  }, [rangeDate]);

  // 가동일 관리 테이블이 초기화된 후 실행함
  useEffect(() => {
    if (manageDowntimeTable.column.length === 0) {
      return;
    }

    // 가동일 관리 테이블 데이터를 불러옴
    getManageDowntimeTableData();

    return () => {};
  }, [manageDowntimeTable.column]);

  // 선택한 가동일 관리 날짜가 변경될 때 실행함
  useEffect(() => {
    // 가동일 관리 테이블 데이터를 불러옴
    getManageDowntimeTableData();

    return () => {};
  }, [selectedCalendarDay]);

  return (
    <PageLayout
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="">
        {/* 검색 */}
        <div className="space-y-5">
          {/* 검색 폼 그리드 */}
          <PbFormGrid label="검색" cols={2}>
            <PbFormGridCol label="조회기간">
              {/* 년월일 피커 */}
              <DatePickerInput
                type="range"
                placeholder="기간을 선택하세요."
                onChange={handleSearchDatePicker_onChange}
                value={searchDatePicker}
                valueFormat="YYYY.MM.DD"
                locale="ko"
                firstDayOfWeek={0}
                clearable={true}
                className="w-full"
              />
            </PbFormGridCol>

            <PbFormGridCol label="담당PM">
              {/* 셀렉트 */}
              <Select
                onChange={handlePmNameSelect_onChange}
                data={pmNameSelectItem}
                value={pmNameSelect}
                placeholder="검색어를 입력하시거나 목록을 선택하세요."
                searchable={true}
                clearable={true}
                className="w-full"
              />
            </PbFormGridCol>

            <PbFormGridCol label="계약명" colSpan={2}>
              {/* 셀렉트 */}
              <Select
                onChange={handleContractSelect_onChange}
                data={contractSelectItem}
                value={contractSelect}
                placeholder="검색어를 입력하시거나 목록을 선택하세요."
                searchable={true}
                clearable={true}
                className="w-full"
              />
            </PbFormGridCol>
          </PbFormGrid>

          {/* 탭 */}
          <Tabs
            variant="outline"
            value={selectedTab}
            onTabChange={(event: any) => setSelectedTab(event)}
          >
            {/* 탭 헤더 */}
            <Tabs.List>
              <Tabs.Tab value="NTWR_CNST">
                <span className="text-lg">네트워크</span>
              </Tabs.Tab>

              <Tabs.Tab value="ESL_NSTL">
                <span className="text-lg">ESL</span>
              </Tabs.Tab>
            </Tabs.List>

            {/* 탭 내용: 네트워크 */}
            <Tabs.Panel value="NTWR_CNST" pt="xl" className="space-y-3">
              {/* 도움말 */}
              <div className="flex justify-start items-center space-x-2">
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['fas', 'circle-info']}
                    className="w-4 h-4 text-blue-500"
                  />
                </div>

                <div className="flex justify-center items-center space-x-2">
                  <div className="px-1 py-0.5 flex justify-center items-center bg-gray-100 rounded-full">
                    <span className="text-base text-gray-600 font-bold">
                      O: 작업(작업일반)
                    </span>
                  </div>

                  <div className="px-1 py-0.5 flex justify-center items-center bg-gray-100 rounded-full">
                    <span className="text-base text-gray-600 font-bold">
                      X: 배정불가(작업추가, 휴일, 작업불가)
                    </span>
                  </div>

                  <div className="px-1 py-0.5 flex justify-center items-center bg-gray-100 rounded-full">
                    <span className="text-base text-gray-600 font-bold">
                      공백: 미지정
                    </span>
                  </div>
                </div>
              </div>

              {/* 가동일 테이블 */}
              <div className="w-full h-120">
                <PbAgGridReact
                  columnDefs={networkDowntimeTable.column}
                  rowData={networkDowntimeTable.data}
                  onCellClicked={(event: any) => {
                    let tmpDay: Date = moment(
                      event.column.colId.replace('day', ''),
                      'YYYYMMDD',
                    ).toDate();

                    // 클릭한 셀 날짜를 가동일 관리 테이블에 적용함
                    setSelectedCalendarDay(tmpDay);
                  }}
                  defaultColDef={{
                    resizable: true,
                    sortable: false,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="none"
                  sizeColumnsToFit={false}
                  visiblePaging={false}
                  getRowStyle={(params: any) => {
                    switch (params.data.rowType) {
                      // 주문 총 건수
                      // case 'sum-order':
                      //   return { backgroundColor: 'rgb(238 242 255)' };

                      // 설치 미배정
                      // case 'install-unassigned':
                      //   return { backgroundColor: 'rgb(238 242 255)' };

                      // 협력사 가용
                      case 'enable-partner':
                        return { backgroundColor: 'rgb(191 219 254)' };

                      default:
                        break;
                    }
                  }}
                  suppressCellFocus={true}
                  className="ag-grid-multiple-header-name ag-grid-cell-vertical-border"
                />
              </div>
            </Tabs.Panel>

            {/* 탭 내용: ESL */}
            <Tabs.Panel value="ESL_NSTL" pt="xl" className="space-y-3">
              {/* 도움말 */}
              <div className="flex justify-start items-center space-x-2">
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['fas', 'circle-info']}
                    className="w-4 h-4 text-blue-500"
                  />
                </div>

                <div className="flex justify-center items-center space-x-2">
                  <div className="px-1 py-0.5 flex justify-center items-center bg-gray-100 rounded-full">
                    <span className="text-base text-gray-600 font-bold">
                      O: 작업(작업일반)
                    </span>
                  </div>

                  <div className="px-1 py-0.5 flex justify-center items-center bg-gray-100 rounded-full">
                    <span className="text-base text-gray-600 font-bold">
                      X: 배정불가(작업추가, 휴일, 작업불가)
                    </span>
                  </div>

                  <div className="px-1 py-0.5 flex justify-center items-center bg-gray-100 rounded-full">
                    <span className="text-base text-gray-600 font-bold">
                      공백: 미지정
                    </span>
                  </div>
                </div>
              </div>

              {/* 가동일 테이블 */}
              <div className="w-full h-120">
                <PbAgGridReact
                  columnDefs={eslDowntimeTable.column}
                  rowData={eslDowntimeTable.data}
                  onCellClicked={(event: any) => {
                    let tmpDay: Date = moment(
                      event.column.colId.replace('day', ''),
                      'YYYYMMDD',
                    ).toDate();

                    // 클릭한 셀 날짜를 가동일 관리 테이블에 적용함
                    setSelectedCalendarDay(tmpDay);
                  }}
                  defaultColDef={{
                    resizable: true,
                    sortable: false,
                    wrapHeaderText: false,
                    autoHeaderHeight: true,
                  }}
                  rowSelection="none"
                  sizeColumnsToFit={false}
                  visiblePaging={false}
                  getRowStyle={(params: any) => {
                    switch (params.data.rowType) {
                      // // 주문 총 건수
                      // case 'sum-order':
                      //   return { backgroundColor: 'rgb(238 242 255)' };

                      // // 설치 미배정
                      // case 'install-unassigned':
                      //   return { backgroundColor: 'rgb(238 242 255)' };

                      // 협력사 가용
                      case 'enable-partner':
                        return { backgroundColor: 'rgb(191 219 254)' };

                      default:
                        break;
                    }
                  }}
                  suppressCellFocus={true}
                  className="ag-grid-multiple-header-name ag-grid-cell-vertical-border"
                />
              </div>
            </Tabs.Panel>
          </Tabs>

          {/* 가동일 관리 */}
          <PbSection label="가동일 관리" className="">
            <div className="flex justify-center items-start space-x-10">
              {/* 달력 */}
              <div className="flex-none">
                <Calendar
                  locale="ko"
                  firstDayOfWeek={0}
                  monthLabelFormat="YYYY년 MM월"
                  date={selectedCalendarDay}
                  onPreviousMonth={(date: Date) => {
                    setSelectedCalendarDay(date);
                  }}
                  onNextMonth={(date: Date) => {
                    setSelectedCalendarDay(date);
                  }}
                  getDayProps={(date: Date) => ({
                    selected:
                      moment(date).format('YYYYMMDD') ===
                      moment(selectedCalendarDay).format('YYYYMMDD'),
                    onClick: () => handleSelectedCalendarDay_onClick(date),
                  })}
                />
              </div>

              {/* 테이블 */}
              <div className="grow space-y-2">
                {/* 저장 버튼 */}
                <div className="flex justify-between items-center">
                  <div className="flex justify-center items-center space-x-2">
                    <div className="flex justify-center items-center">
                      <FontAwesomeIcon
                        icon={['far', 'calendar']}
                        className="w-4 h-4 text-blue-500"
                      />
                    </div>

                    <span className="text-base text-gray-600 font-bold">
                      {moment(selectedCalendarDay).format('YYYY년 MM월 DD일')}{' '}
                      {['일', '월', '화', '수', '목', '금', '토'][
                        moment(selectedCalendarDay).day()
                      ] + '요일'}
                    </span>
                  </div>

                  <div className="flex justify-end items-center space-x-3">
                    {/* 도움말 */}
                    <div className="flex justify-center items-center space-x-1">
                      <div className="flex justify-center items-center">
                        <FontAwesomeIcon
                          icon={['fas', 'circle-info']}
                          className="w-4 h-4 text-blue-500"
                        />
                      </div>

                      <div className="flex justify-center items-center space-x-2">
                        <span className="text-base text-gray-600 font-bold">
                          행을 더블클릭하여 [배정구분]을 변경할 수 있습니다.
                        </span>
                      </div>
                    </div>

                    {/* 버튼 */}
                    <Button
                      onClick={handleManageDowntimeSaveButton_onClick}
                      color="indigo"
                      radius="xl"
                    >
                      저장
                    </Button>
                  </div>
                </div>

                {/* 가동일 관리 테이블 */}
                <div className="w-full h-96">
                  <PbAgGridReact
                    columnDefs={manageDowntimeTable.column}
                    rowData={manageDowntimeTable.data}
                    onRowDoubleClicked={
                      handleManageDowntimeTable_onRowDoubleClicked
                    }
                    defaultColDef={{
                      resizable: true,
                      sortable: true,
                      wrapHeaderText: false,
                      autoHeaderHeight: true,
                    }}
                    rowSelection="single"
                    sizeColumnsToFit={true}
                    visiblePaging={false}
                  />
                </div>
              </div>
            </div>
          </PbSection>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220603Page;
