import React, { PropsWithChildren, useEffect, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { PbFormGrid } from '../PbFormGrid';
import { PbFormGridCol } from '../PbFormGridCol';
import { PbButton } from '../PbButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PbMonthPicker } from '../PbMonthPicker';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  addComponentModalStore,
  addPageScrollTabComponentModalStore,
  componentModalStore,
  removeAllComponentModalStore,
  removeComponentModalStore,
  selectPageScrollTabComponentModalStore,
} from '../../stores/componentModal.store';
import {
  IApiResult,
  IComponentModal,
  ILeftMenu,
  ILoginUser,
  IModal,
  IOptionItem,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModalPageScrollTabStep,
  IComponentModalPageScrollTab,
} from '../../interfaces/app.interface';
import { useNavigate } from 'react-router-dom';
import { loginStore } from '../../stores/login.store';
import { selectedPageStore } from '../../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../../stores/modal.store';
import * as cmnApi from '../../apis/cmn.api';
import _ from 'lodash';
import { PbTable } from '../PbTable';
import moment from 'moment/moment';
import { nanoid } from 'nanoid';
import { getSlsPrtn } from '../../apis/sls.api';
import * as csApi from '../../apis/cs.api';
import { PbInputNumber } from '../PbInputNumber';
import { pageContentStore } from '../../stores/page.store';
import { addRefreshListStore } from '../../stores/refreshList.store';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import { DatePickerInput } from '@mantine/dates';
import {
  Button,
  Group,
  NumberInput,
  Select,
  Tabs,
  TextInput,
  Radio,
  Checkbox,
} from '@mantine/core';
import PbSection from '../PbSection/PbSection.component';
import { IGroupCellRendererParams } from 'ag-grid-community';
import { PbAgGridReact } from '../PbAgGridReact';
import * as appUtil from '../../utils/app.util';

interface IComponentProps {
  id?: string;
  thrtId?: string;
  webType?: string;
  useModifyMode?: boolean;
  data?: any;
  onClick?: () => void;
}

/**
 * 시스템 관리 > 권한 관리 > 조회, 수정
 * @param id 컴포넌트 모달 아이디
 * @param thrtId 권한 아이디
 * @param webType WEB , Mobile 구분
 * @param useModifyMode 수정 모드 사용 여부
 * @param data <인자>
 * @param onClick <이벤트>
 * @constructor
 */
const MN2302220850 = ({
  id = '',
  thrtId = '',
  webType = 'WEB',
  useModifyMode = false,
  data,
  onClick,
}: PropsWithChildren<IComponentProps>) => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 내비게이트를 정의함
  const navigate = useNavigate();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 삭제할 전체 컴포넌트 모달 저장소를 정의함
  const [removeAllComponentModal, setRemoveAllComponentModal] =
    useRecoilState<boolean>(removeAllComponentModalStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 컴포넌트 모달 저장소를 정의함
  const [componentModal, setComponentModal] =
    useRecoilState<IComponentModal[]>(componentModalStore);

  // 컴포넌트 모달에 페이지 스크롤 탭을 추가할 저장소를 정의함
  const [addPageScrollTabComponentModal, setAddPageScrollTabComponentModal] =
    useRecoilState<{ id: string; tab: IComponentModalPageScrollTab } | null>(
      addPageScrollTabComponentModalStore,
    );

  // 컴포넌트 모달의 페이지 스크롤 탭의 선택을 변경할 저장소를 정의함
  const [
    selectPageScrollTabComponentModal,
    setSelectPageScrollTabComponentModal,
  ] = useRecoilState<{ id: string; active: string } | null>(
    selectPageScrollTabComponentModalStore,
  );

  // 추가할 목록 새로고침 저장소를 정의함
  const [addRefreshList, setAddRefreshList] =
    useRecoilState<string>(addRefreshListStore);

  /**
   * 권한명
   */

  // 권한명 중복 확인
  const [authNameConfirm, setAuthNameConfirm] = useState<string>('');

  // 기본정보 > 권한명 입력을 정의함
  const [thrtNameInput, setThrtNameInput] = useState<string>('');

  // 기본정보 > 권한명 기존값을 정의함
  const [thrtNameOld, setThrtNameOld] = useState<string>('');

  // 기본정보 > 권한명 값을 변경함
  const handleAuthNameInput_onChange = (event: any) => {
    setAuthNameConfirm('N');
    setThrtNameInput(event.target.value);
  };

  // 권한명 > 중복확인 버튼을 정의함
  const handleDuplicateConfirmButton_onClick = () => {
    setAuthNameConfirm('N');

    if (_.isEmpty(thrtNameInput.trim())) {
      setModal({
        title: '알림',
        content: '권한명을 입력해주세요.',
      });
      return;
    }

    // 권한명 중복확인
    cmnApi
      .getCheckAuthority({
        thrtName: thrtNameInput, // 권한명
      })
      .then((data: IApiResult) => {
        switch (data.code) {
          // 중복 확인 : 권한명 사용 가능
          case '200':
            setAuthNameConfirm('Y');
            setModal({
              title: '알림',
              content: '사용 가능한 권한명 입니다.',
            });
            break;

          // 중복 확인 : 권한명 사용 불가
          case '500':
            setAuthNameConfirm('N');
            setModal({
              title: '알림',
              content: data.message,
            });
            break;

          default:
            break;
        }
      });
  };

  /* //권한명 */

  /**
   * 사용여부
   */

  // 상세 검색 > 사용 여부 라디오 버튼을 정의함
  const [useYnRadio, setUseYnRadio] = useState<string>('Y');

  // 상세 검색 > 사용 여부 셀렉트에서 선택한 아이템을 변경함
  const handleUseYnSelect_onChange = (event: any) => {
    setUseYnRadio(event);
  };

  /* //사용여부 */

  /**
   * 등록일
   */

  // 기본정보 폼 그리드 > 등록일 > 날짜 피커를 정의함
  const [addDatePicker, setAddDatePicker] = useState<Date | null>(null);

  // 기본정보 폼 그리드 > 등록일 > 날짜 피커를 변경함
  const handleAddDatePicker_onChange = (event: any) => {
    setAddDatePicker(event.target.value);
  };

  /* //등록일 */

  /**
   * 수정일
   */

  // 기본정보 폼 그리드 > 수정일 > 날짜 피커를 정의함
  const [modifyDatePicker, setModifyDatePicker] = useState<Date | null>(null);

  // 기본정보 폼 그리드 > 수정일 > 날짜 피커를 변경함
  const handleModifyDatePicker_onChange = (event: any) => {
    setModifyDatePicker(event.target.value);
  };

  /* //수정일 */

  /**
   * 권한 목록(웹)
   */

  // 권한 상세 > 기본정보 > 권한설정 > 테이블을 정의함
  const [webAuthListTable, setWebAuthListTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 권한 상세 > 기본정보 > 권한설정 > 테이블 참조를 정의함
  const webAuthListTableRef = useRef<any>(null);

  // 페이지 스크롤 탭을 초기화함
  const initPageScrollTab = () => {
    let tmpTab: IComponentModalPageScrollTabStep[] = [];

    // 편집 상태에 따라 페이지 스크롤 탭을 초기화함
    if (!thrtId) {
      // 신규
      tmpTab = [
        {
          label: '기본',
          targetId: 'step-1',
        },
        {
          label: '권한',
          targetId: 'step-2',
        },
      ];
    } else if (thrtId && !useModifyMode) {
      // 조회
      tmpTab = [
        {
          label: '기본',
          targetId: 'step-1',
        },
        {
          label: '권한',
          targetId: 'step-2',
        },
      ];
    } else if (thrtId && useModifyMode) {
      // 수정
      tmpTab = [
        {
          label: '기본',
          targetId: 'step-1',
        },
        {
          label: '권한',
          targetId: 'step-2',
        },
      ];
    }

    // 컴포넌트 모달에 페이지 스크롤 탭을 추가함
    setAddPageScrollTabComponentModal({
      id: id,
      tab: {
        active: 'step-1',
        tab: tmpTab,
      },
    });
  };

  // 권한등록 > 권한설정 테이블을 초기화함
  const initWebAuthListTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 기본 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '메뉴명',
        children: [
          { headerName: '1depth', field: 'oneKrnMenu' },
          { headerName: '2depth', field: 'twoKrnMenu' },
          // { headerName: '3depth', field: 'threeKrnMenu' },
          // { headerName: '4depth', field: 'fourKrnMenu' },
        ],
      },
      {
        field: 'mnthRltnUseYn',
        headerName: '사용 여부',
        cellRenderer: (params: any) => (
          <div className="h-full flex justify-start items-center">
            <Checkbox
              onChange={handleWebAuthListTableCheckbox_onChange}
              defaultChecked={params.value === 'Y' ? true : false}
              value={[
                params.data.oneMenuId,
                params.data.twoMenuId,
                params.data.threeMenuId,
              ].join(',')}
              disabled={thrtId && !useModifyMode ? true : false}
            />
          </div>
        ),
        width: 100,
        suppressSizeToFit: true,
      },
    );

    // 현황 테이블에 적용함
    setWebAuthListTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /**
   * 권한 목록(모바일)
   */

  // 권한 상세 > 기본정보 > 권한설정 > 테이블을 정의함
  const [mobileAuthListTable, setMobileAuthListTable] = useState<{
    column: any;
    data: any;
  }>({ column: [], data: [] });

  // 권한 상세 > 기본정보 > 권한설정 > 테이블 참조를 정의함
  const mobileAuthListTableRef = useRef<any>(null);

  // 권한등록 > 권한설정 테이블을 초기화함
  const initMobileAuthListTable = () => {
    // 컬럼을 정의함
    let tmpColumn: any = [];

    // 기본 컬럼에 추가함
    tmpColumn.push(
      {
        headerName: '메뉴명',
        children: [
          { headerName: '1depth', field: 'oneKrnMenu' },
          { headerName: '2depth', field: 'twoKrnMenu' },
          // { headerName: '3depth', field: 'threeKrnMenu' },
          // { headerName: '4depth', field: 'fourKrnMenu' },
        ],
      },
      {
        field: 'mnthRltnUseYn',
        headerName: '사용 여부',
        cellRenderer: (params: any) => (
          <div className="h-full flex justify-start items-center">
            <Checkbox
              onChange={handleMobileAuthListTableCheckbox_onChange}
              defaultChecked={params.value === 'Y' ? true : false}
              value={[
                params.data.oneMenuId,
                params.data.twoMenuId,
                params.data.threeMenuId,
              ].join(',')}
              disabled={thrtId && !useModifyMode ? true : false}
            />
          </div>
        ),
        width: 100,
        suppressSizeToFit: true,
      },
    );

    // 현황 테이블에 적용함
    setMobileAuthListTable((pre: { column: any; data: any }) => ({
      ...pre,
      column: tmpColumn,
    }));
  };

  /* // 권한 목록 */

  /**
   * 상세 데이타(웹)
   */

  // 권한 정보 상세 데이터를 정의함
  const [cmnWebAuthData, setCmnWebAuthData] = useState<any>(null);

  // 권한 정보 상세 데이터를 불러옴
  const getCmnWebAuthDetail = () => {
    cmnApi
      .getAuthority({
        thrtId: thrtId,
        menuDvsnCode: 'WEB',
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 권한 정보 상세 데이터에 적용함
          // setCmnWebAuthData(data.data);
          setCmnWebAuthData({
            ...data.data,
            list: data.data.list.filter(
              (item: any) =>
                item.threeMenuId === '' &&
                item.fourMenuId === '' &&
                item.oneKrnMenu !== 'HOME',
            ),
          });
        }
      });
  };

  // 등록을 위한 권한 전체 데이터를 불러옴
  const getWebAuthorityTarget = () => {
    cmnApi
      .getAuthorityTarget({
        menuDvsnCode: 'WEB',
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 권한 정보 상세 데이터에 적용함
          // 검색 결과 테이블에 적용함
          setWebAuthListTable((pre: { column: ITableColumn[]; data: any }) => ({
            ...pre,
            data: data.data.list.filter(
              (item: any) =>
                item.threeMenuId === '' &&
                item.fourMenuId === '' &&
                item.oneKrnMenu !== 'HOME',
            ),
          }));
        }
      });
  };

  /**
   * 상세 데이타(모바일)
   */

  // 권한 정보 상세 데이터를 정의함
  const [cmnMobileAuthData, setCmnMobileAuthData] = useState<any>(null);

  // 권한 정보 상세 데이터를 불러옴
  const getCmnMobileAuthDetail = () => {
    cmnApi
      .getAuthority({
        thrtId: thrtId,
        menuDvsnCode: 'MBL',
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 권한 정보 상세 데이터에 적용함
          setCmnMobileAuthData(
            data.data.list.filter(
              (item: any) =>
                item.threeMenuId === '' &&
                item.fourMenuId === '' &&
                item.oneKrnMenu !== 'HOME',
            ),
          );
        }
      });
  };

  // 등록을 위한 권한 전체 데이터를 불러옴
  const getMobileAuthorityTarget = () => {
    cmnApi
      .getAuthorityTarget({
        menuDvsnCode: 'MBL',
      })
      .then((data: IApiResult) => {
        if (data.code === '200') {
          // 권한 정보 상세 데이터에 적용함
          // 검색 결과 테이블에 적용함
          setMobileAuthListTable(
            (pre: { column: ITableColumn[]; data: any }) => ({
              ...pre,
              data: data.data.list.filter(
                (item: any) =>
                  item.threeMenuId === '' &&
                  item.fourMenuId === '' &&
                  item.oneKrnMenu !== 'HOME',
              ),
            }),
          );
        }
      });
  };

  /* 권한 상세 탭
   * [2023.04.24] 권한 설정 탭 & 권한 설정 대상 메뉴 목록 관련 설정 추가 (곽상준)
   */

  /* 권한 설정 탭 */
  //권한 설정 선택 탭 데이터를 정의함
  const [thrtCategoryTab, setThrtCategoryTab] = useState<string>(webType);

  //권한 설정 선택 이벤트를 정의
  const handleThrtCategoryTab_onChange = (event: any) => {
    setThrtCategoryTab(event);
  };

  const handleWebAuthListTableCheckbox_onChange = (event: any) => {
    // 테이블의 전체 데이터를 불러옴
    let tmpRowData: any = [];

    webAuthListTableRef.current.api.forEachNode((node: any) =>
      tmpRowData.push(node.data),
    );

    // 선택한 행의 메뉴 아이디를 각각 분리함
    let tmpRowId: string[] = event.target.value.split(',');

    // 수정할 행의 순서를 불러옴
    let tmpIndex: number = _.findIndex(tmpRowData, {
      oneMenuId: tmpRowId[0],
      twoMenuId: tmpRowId[1],
      threeMenuId: tmpRowId[2],
    });

    // 체크박스의 체크 여부에 따라 값을 적용함
    tmpRowData[tmpIndex]['mnthRltnUseYn'] = event.target.checked ? 'Y' : 'N';
  };

  const handleMobileAuthListTableCheckbox_onChange = (event: any) => {
    // 테이블의 전체 데이터를 불러옴
    let tmpRowData: any = [];

    mobileAuthListTableRef.current.api.forEachNode((node: any) =>
      tmpRowData.push(node.data),
    );

    // 선택한 행의 메뉴 아이디를 각각 분리함
    let tmpRowId: string[] = event.target.value.split(',');

    // 수정할 행의 순서를 불러옴
    let tmpIndex: number = _.findIndex(tmpRowData, {
      oneMenuId: tmpRowId[0],
      twoMenuId: tmpRowId[1],
      threeMenuId: tmpRowId[2],
    });

    // 체크박스의 체크 여부에 따라 값을 적용함
    tmpRowData[tmpIndex]['mnthRltnUseYn'] = event.target.checked ? 'Y' : 'N';
  };

  /* // 권한 상세 탭 */

  /**
   * 처리
   */

  // 컴포넌트 모달 > 삭제 버튼을 클릭함
  const handleRemoveButton_onClick = () => {
    setModal({
      title: '확인',
      content: `권한 [${cmnWebAuthData.thrtName}] 정보를 삭제하시겠습니까?`,
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              // 권한 정보를 삭제함
              cmnApi
                .deleteAuthority({
                  thrtId: thrtId,
                })
                .then((data: IApiResult) => {
                  switch (data.code) {
                    case '200':
                      // 모달을 출력함
                      setModal({
                        title: '알림',
                        content: '삭제하였습니다.',
                        callback: () => setRemoveComponentModal(id),
                      });
                      break;

                    case '':
                      // 모달을 출력함
                      setModal({
                        title: t('common.error'),
                        content: '삭제에 실패하였습니다.',
                      });
                      break;

                    default:
                      break;
                  }
                });
            }}
          />
        </div>
      ),
    });
  };

  // 컴포넌트 모달 > 수정 전환 버튼을 클릭함
  const handleGoModifyButton_onClick = () => {
    // 컴포넌트 모달을 닫음
    setRemoveComponentModal(id);

    setTimeout(() => {
      // 수정 컴포넌트 모달을 추가함
      let tmpId: string = nanoid();

      // 컴포넌트 모달을 추가함
      setAddComponentModal({
        id: tmpId,
        title: '권한 정보 수정',
        content: (
          <MN2302220850
            id={tmpId}
            thrtId={thrtId}
            useModifyMode={true}
            webType={thrtCategoryTab}
          />
        ),
        size: 1500,
      });
    }, 100);
  };

  // 컴포넌트 모달 > 저장 버튼을 클릭함
  const handleSaveButton_onClick = () => {
    // 기본정보 > 권한명 확인함
    if (_.isEmpty(thrtNameInput.trim())) {
      setModal({
        title: '알림',
        content: '권한명을 입력하세요.',
      });

      return;
    }

    // 권한명 중복 확인
    if (authNameConfirm === 'Y') {
      // 중복 확인됨
    } else {
      setModal({
        title: '알림',
        content: '권한명 중복확인을 해주세요.',
      });
      return;
    }

    // 권한 설정에서 체크한 체크박스의 값을 가져옴(메뉴 아이디)
    let tmpThrtRltnListMap: any[] = [];

    webAuthListTable.data
      .filter((item: any) => item.mnthRltnUseYn === 'Y')
      .map((item: any) => {
        tmpThrtRltnListMap.push({
          [item.mnthRltnTargetId]: 'Y',
        });
      });

    mobileAuthListTable.data
      .filter((item: any) => item.mnthRltnUseYn === 'Y')
      .map((item: any) => {
        tmpThrtRltnListMap.push({
          [item.mnthRltnTargetId]: 'Y',
        });
      });

    // 권한 정보 등록 저장 처리
    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              // 권한정보 등록을 처리함
              cmnApi
                .postAuthority({
                  thrtName: thrtNameInput.trim(),
                  thrtDscr: '',
                  menuDvsnCode: thrtCategoryTab,
                  mnthRltnListMap: tmpThrtRltnListMap,
                  useYn: useYnRadio,
                })
                .then((data: IApiResult) => {
                  if (data.code === '200') {
                    setModal({
                      title: '알림',
                      content: '저장하였습니다.',
                      callback: () => {
                        // 목록 새로고침을 추가함
                        setAddRefreshList('MN2302220802Table');

                        // 컴포넌트 모달을 닫음
                        setRemoveComponentModal(id);
                      },
                    });
                  } else {
                    console.log('> 권한 정보 등록 Error:', data);

                    setModal({
                      title: '오류',
                      content: '저장에 실패하였습니다.',
                    });
                  }
                })
                .catch((error: any) => {
                  console.log('> 권한 정보 등록 Error:', error);

                  setModal({
                    title: '오류',
                    content: '저장에 실패하였습니다.',
                  });
                });
            }}
          />
        </div>
      ),
    });
  };

  // 컴포넌트 모달 > 저장 버튼(수정)을 클릭함
  const handleSaveUpdateButton_onClick = () => {
    // 권한 정보 수정의 입력 값 유효성을 확인함

    // 기본정보 > 권한명 확인함
    if (_.isEmpty(thrtNameInput.trim())) {
      setModal({
        title: '알림',
        content: '권한명을 입력하세요.',
      });

      return;
    }

    // 권한명 중복 확인
    if (authNameConfirm === 'Y') {
      // 중복 확인됨
    } else {
      if (thrtNameOld === thrtNameInput.trim()) {
        // 값을 변경 했었어도, 기존 값과 같다면, 중복확인 없이 저장 가능
      } else {
        setModal({
          title: '알림',
          content: '권한명 중복확인을 해주세요.',
        });
        return;
      }
    }

    // 권한 설정에서 체크한 체크박스의 값을 가져옴(메뉴 아이디)
    let tmpWebThrtRltnListMap: any[] = [];

    webAuthListTable.data
      .filter((item: any) => item.mnthRltnUseYn === 'Y')
      .map((item: any) => {
        tmpWebThrtRltnListMap.push({
          [item.mnthRltnTargetId]: 'Y',
        });
      });

    // if (tmpWebThrtRltnListMap.length === 0) {
    //   setModal({
    //     title: '알림',
    //     content: '권한을 부여할 웹의 메뉴를 체크하여 선택해주세요.',
    //   });
    //
    //   setThrtCategoryTab('WEB');
    //   return;
    // }

    // 권한 설정에서 체크한 체크박스의 값을 가져옴(메뉴 아이디)
    let tmpMobileThrtRltnListMap: any[] = [];

    mobileAuthListTable.data
      .filter((item: any) => item.mnthRltnUseYn === 'Y')
      .map((item: any) => {
        tmpMobileThrtRltnListMap.push({
          [item.mnthRltnTargetId]: 'Y',
        });
      });

    // if (tmpMobileThrtRltnListMap.length === 0) {
    //   setModal({
    //     title: '알림',
    //     content: '권한을 부여할 모바일의 메뉴를 체크하여 선택해주세요.',
    //   });
    //
    //   setThrtCategoryTab('MBL');
    //   return;
    // }

    // 권한 정보 수정 저장 처리
    setModal({
      title: '확인',
      content: '저장하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <div>
          <PbButton
            label="예"
            backgroundColor="purple"
            size="sm"
            onClick={() => {
              setRemoveModal(true);

              // 권한정보 수정을 처리함(웹)
              cmnApi
                .putAuthority({
                  thrtId: thrtId, // 권한 아이디
                  menuDvsnCode: 'WEB', // Tab 구분(WEB, Mobile)
                  thrtDscr: '', // 권한 설명
                  thrtName: thrtNameInput.trim(), // 권한 이름
                  useYn: useYnRadio,
                  mnthRltnListMap: tmpWebThrtRltnListMap, // 권한 설정 - 사용여부
                })
                .then((data: IApiResult) => {
                  if (data.code === '200') {
                    // 권한정보 수정을 처리함(모바일)
                    cmnApi
                      .putAuthority({
                        thrtId: thrtId, // 권한 아이디
                        menuDvsnCode: 'MBL', // Tab 구분(WEB, Mobile)
                        thrtDscr: '', // 권한 설명
                        thrtName: thrtNameInput.trim(), // 권한 이름
                        useYn: useYnRadio,
                        mnthRltnListMap: tmpMobileThrtRltnListMap, // 권한 설정 - 사용여부
                      })
                      .then((data: IApiResult) => {
                        if (data.code === '200') {
                          setModal({
                            title: '알림',
                            content: '저장하였습니다.',
                            callback: () => {
                              // 목록 새로고침을 추가함
                              setAddRefreshList('MN2302220802Table');

                              // 컴포넌트 모달을 닫음
                              setRemoveComponentModal(id);

                              setTimeout(() => {
                                // 조회 컴포넌트 모달을 추가함
                                let tmpId: string = nanoid();

                                // 컴포넌트 모달을 추가함
                                setAddComponentModal({
                                  id: tmpId,
                                  title: '권한 정보 상세',
                                  content: (
                                    <MN2302220850
                                      id={tmpId}
                                      thrtId={thrtId}
                                      webType={thrtCategoryTab}
                                    />
                                  ),
                                  size: 1500,
                                });
                              }, 100);
                            },
                          });
                        } else {
                          console.log('> 권한 정보 수정 Error:', data);

                          setModal({
                            title: '오류',
                            content: '저장에 실패하였습니다.',
                          });
                        }
                      })
                      .catch((error: any) => {
                        console.log('> 권한 정보 수정 Error:', error);

                        setModal({
                          title: '오류',
                          content: '저장에 실패하였습니다.',
                        });
                      });
                  } else {
                    console.log('> 권한 정보 수정 Error:', data);

                    setModal({
                      title: '오류',
                      content: '저장에 실패하였습니다.',
                    });
                  }
                })
                .catch((error: any) => {
                  console.log('> 권한 정보 수정 Error:', error);

                  setModal({
                    title: '오류',
                    content: '저장에 실패하였습니다.',
                  });
                });
            }}
          />
        </div>
      ),
    });
  };

  // 페이지 로딩 후 한번만 실행함
  useEffect(() => {
    // 페이지 스크롤 탭을 초기화함
    initPageScrollTab();

    // 기본정보 > 권한명 값을 초기화함
    setThrtNameInput('');

    // 기본정보 폼 그리드 > 등록일 > 날짜 피커를 초기화함
    setAddDatePicker(moment().toDate());

    // 기본정보 폼 그리드 > 수정일 > 날짜 피커를 초기화함
    setModifyDatePicker(moment().toDate());

    // 권한등록 > 권한설정 테이블을 초기화함
    initWebAuthListTable();
    initMobileAuthListTable();

    if (thrtId) {
      // 상세/수정의 경우 호출됨
      // 권한명 중복 확인
      setAuthNameConfirm('Y');

      // 권한 상세 데이터를 불러옴
      getCmnWebAuthDetail();
      getCmnMobileAuthDetail();
    } else {
      // 권한명 중복 확인
      setAuthNameConfirm('N');

      // 권한 상세 데이터를 불러옴
      getWebAuthorityTarget();
      getMobileAuthorityTarget();
    }

    return () => {};
  }, []);

  // 상세/수정의 경우 기존 데이타 설정
  useEffect(() => {
    if (!cmnWebAuthData) {
      return;
    }

    console.log('> cmnWebAuthData:', cmnWebAuthData);

    // 기본정보 > 권한명 설정
    setThrtNameInput(cmnWebAuthData.thrtName);

    // 기본정보 > 권한명 수정전 값 설정
    setThrtNameOld(cmnWebAuthData.thrtName);

    // 상세 검색 > 사용 여부 설정
    setUseYnRadio(cmnWebAuthData.useYn);

    // 기본정보 폼 그리드 > 등록일 설정
    setAddDatePicker(moment(cmnWebAuthData.rgstDate, 'YYYY-MM-DD').toDate());

    // 기본정보 폼 그리드 > 수정일 설정
    setModifyDatePicker(moment(cmnWebAuthData.mdfcDate, 'YYYY-MM-DD').toDate());

    // 검색 결과 테이블에 적용함
    setWebAuthListTable((pre: { column: ITableColumn[]; data: any }) => ({
      ...pre,
      data: cmnWebAuthData.list.filter(
        (item: any) =>
          item.threeMenuId === '' &&
          item.fourMenuId === '' &&
          item.oneKrnMenu !== 'HOME',
      ),
    }));

    return () => {};
  }, [cmnWebAuthData]);

  // 상세/수정의 경우 기존 데이타 설정
  useEffect(() => {
    if (!cmnMobileAuthData) {
      return;
    }

    // 검색 결과 테이블에 적용함
    setMobileAuthListTable((pre: { column: ITableColumn[]; data: any }) => ({
      ...pre,
      data: cmnMobileAuthData,
    }));

    return () => {};
  }, [cmnMobileAuthData]);

  // //권한 설정 탭 변경 설정
  // useEffect(() => {
  //   //TODO : 권한 설정 대상 메뉴 목록 출력 (곽상준)
  //   //권한 메뉴 대상 목록 조회 (param : thrtCategoryTab (WEB or MBL))
  //   if (thrtId) {
  //     // 상세/수정의 경우 호출됨
  //     // 권한 상세 데이터를 불러옴
  //     getCmnWebAuthDetail();
  //     getCmnMobileAuthDetail();
  //   } else {
  //     // 등록
  //     getWebAuthorityTarget();
  //   }
  //
  //   return () => {};
  // }, [thrtCategoryTab]);

  // 컴포넌트 모달의 페이지 스크롤 탭이 변경될 때 실행함
  useEffect(() => {
    const tmpComponentModal: IComponentModal = _.find(componentModal, {
      id: id,
    })!;

    // 컴포넌트 모달에서 지정한 DIV ID로 페이지를 스크롤함
    appUtil.pageScrollToTarget(id, tmpComponentModal.pageScrollTab?.active!);

    return () => {};
  }, [_.find(componentModal, { id: id })]);

  /* //처리 */

  return (
    <div className="space-y-7">
      {/* 컴포넌트 모달 내용 */}
      <div className="space-y-5">
        {/* 기본 정보 폼 그리드 */}
        <PbFormGrid id="step-1" label="기본 정보" cols={2}>
          <PbFormGridCol label="권한명" withAsterisk={true}>
            {/* 텍스트 입력 */}
            <TextInput
              placeholder="권한명을 입력하세요."
              onChange={handleAuthNameInput_onChange}
              value={thrtNameInput}
              readOnly={thrtId && !useModifyMode ? true : false}
              variant={thrtId && !useModifyMode ? 'unstyled' : 'default'}
              className="w-full"
            />

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              hidden={thrtId && !useModifyMode ? true : false}
              onClick={handleDuplicateConfirmButton_onClick}
            >
              중복 확인
            </Button>
          </PbFormGridCol>
          <PbFormGridCol label="사용 여부">
            <Radio.Group
              name="radioGroup1"
              onChange={handleUseYnSelect_onChange}
              value={useYnRadio}
              size="sm"
            >
              <Group mt="xs">
                <Radio
                  value="Y"
                  label="사용"
                  //readOnly={thrtId && !useModifyMode ? true : false}
                  disabled={thrtId && !useModifyMode ? true : false}
                />
                <Radio
                  value="N"
                  label="미사용"
                  //readOnly={thrtId && !useModifyMode ? true : false}
                  disabled={thrtId && !useModifyMode ? true : false}
                />
              </Group>
            </Radio.Group>
          </PbFormGridCol>
          <PbFormGridCol label="등록일" isHidden={true}>
            {/* 날짜 피커 */}
            <DatePickerInput
              onChange={handleAddDatePicker_onChange}
              value={addDatePicker}
              valueFormat="YYYY.MM.DD"
              locale="ko"
              firstDayOfWeek={0}
              clearable={true}
              className="w-full"
              readOnly={true}
            />
          </PbFormGridCol>
          <PbFormGridCol label="수정일" isHidden={true}>
            {/* 날짜 피커 */}
            <DatePickerInput
              onChange={handleModifyDatePicker_onChange}
              value={modifyDatePicker}
              valueFormat="YYYY.MM.DD"
              locale="ko"
              firstDayOfWeek={0}
              clearable={true}
              className="w-full"
              readOnly={true}
            />
          </PbFormGridCol>
        </PbFormGrid>
      </div>

      {/* 권한 설정 섹션 */}
      <PbSection id="step-2" label="권한 설정">
        {/* 탭 */}
        <Tabs
          variant="outline"
          value={thrtCategoryTab}
          onTabChange={handleThrtCategoryTab_onChange}
        >
          {/* 탭 헤더 */}
          <Tabs.List>
            <Tabs.Tab
              value="WEB"
              icon={
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['fab', 'chrome']}
                    className="w-4 h-4 text-gray-600"
                  />
                </div>
              }
            >
              <span className="text-lg">WEB</span>
            </Tabs.Tab>
            <Tabs.Tab
              value="MBL"
              icon={
                <div className="flex justify-center items-center">
                  <FontAwesomeIcon
                    icon={['fas', 'mobile-screen-button']}
                    className="w-4 h-4 text-gray-600"
                  />
                </div>
              }
            >
              <span className="text-lg">MOBILE</span>
            </Tabs.Tab>
          </Tabs.List>

          {/* 웹 */}
          <Tabs.Panel value="WEB" pt="xs">
            {/* 테이블 */}
            <div className="w-full h-152">
              <PbAgGridReact
                refs={webAuthListTableRef}
                columnDefs={webAuthListTable.column}
                rowData={webAuthListTable.data}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                  cellRendererParams: {
                    checkbox: true,
                  } as IGroupCellRendererParams,
                }}
                rowSelection="single"
                sizeColumnsToFit={true}
              />
            </div>
          </Tabs.Panel>

          {/* 모바일 */}
          <Tabs.Panel value="MBL" pt="xs">
            {/* 테이블 */}
            <div className="w-full h-152">
              <PbAgGridReact
                refs={mobileAuthListTableRef}
                columnDefs={mobileAuthListTable.column}
                rowData={mobileAuthListTable.data}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  wrapHeaderText: false,
                  autoHeaderHeight: true,
                  cellRendererParams: {
                    checkbox: true,
                  } as IGroupCellRendererParams,
                }}
                rowSelection="single"
                sizeColumnsToFit={true}
              />
            </div>
          </Tabs.Panel>
        </Tabs>
      </PbSection>

      {/*/!* 테이블 *!/*/}
      {/*<div className="w-full h-152">*/}
      {/*  <PbAgGridReact*/}
      {/*    refs={authListTableRef}*/}
      {/*    columnDefs={authListTable.column}*/}
      {/*    // rowData={authListTable.data}*/}
      {/*    rowData={authListTable.data.filter(*/}
      {/*      (item: any) => item.oneKrnMenu !== 'HOME',*/}
      {/*    )}*/}
      {/*    defaultColDef={{*/}
      {/*      resizable: true,*/}
      {/*      sortable: true,*/}
      {/*      wrapHeaderText: false,*/}
      {/*      autoHeaderHeight: true,*/}
      {/*      cellRendererParams: {*/}
      {/*        checkbox: true,*/}
      {/*      } as IGroupCellRendererParams,*/}
      {/*    }}*/}
      {/*    rowSelection="single"*/}
      {/*    sizeColumnsToFit={true}*/}
      {/*  />*/}
      {/*</div>*/}

      {/* 테이블 */}
      {/*<div className="w-full h-152 mt-0">*/}
      {/*  <AgGridReact*/}
      {/*    ref={authListTableRef}*/}
      {/*    columnDefs={authListTable.column} //*/}
      {/*    rowData={authListTable.data}*/}
      {/*    defaultColDef={{*/}
      {/*      initialWidth: 150,*/}
      {/*      resizable: true,*/}
      {/*      sortable: true,*/}
      {/*      wrapHeaderText: false,*/}
      {/*      autoHeaderHeight: true,*/}
      {/*      suppressSizeToFit: false,*/}
      {/*      cellRendererParams: {*/}
      {/*        checkbox: true,*/}
      {/*      } as IGroupCellRendererParams,*/}
      {/*    }}*/}
      {/*    onGridReady={(event) => event.api.sizeColumnsToFit()}*/}
      {/*    rowSelection="single"*/}
      {/*    overlayNoRowsTemplate="데이터가 없습니다."*/}
      {/*    className="ag-theme-alpine"*/}
      {/*  />*/}
      {/*</div>*/}

      {/* 컴포넌트 모달 버튼 */}
      <div className="component-modal-button-area space-x-2">
        {/* 신규 상태 */}
        {!thrtId && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveButton_onClick}
            >
              저장
            </Button>
          </>
        )}

        {/* 조회 상태 */}
        {thrtId && !useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleGoModifyButton_onClick}
            >
              수정 전환
            </Button>
          </>
        )}

        {/* 수정 상태 */}
        {thrtId && useModifyMode && (
          <>
            {/* 버튼 */}
            <Button
              variant="outline"
              color="gray"
              radius="xl"
              size="md"
              onClick={() => setRemoveComponentModal(id)}
            >
              닫기
            </Button>

            <Button
              variant="outline"
              color="pink"
              radius="xl"
              size="md"
              onClick={handleRemoveButton_onClick}
            >
              삭제
            </Button>

            {/* 버튼 */}
            <Button
              color="indigo"
              radius="xl"
              size="md"
              onClick={handleSaveUpdateButton_onClick}
            >
              저장
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default MN2302220850;
