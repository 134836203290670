import axios, { AxiosResponse } from 'axios';
import api from '../utils/axios.util';

/**
 * B1: B1 인터페이스
 */

export const getBusinessPartners = async (params: any) => {
  const response = await axios.post('/api/b1/BusinessPartners', params);
  return response.data;
};

export const getB = (params: any) => {
  return '123';
};

/**
 * 재고 이동 히스토리 조회
 * GET /api/b1/inventoryTransferRequests
 * @param params startDate - 시작일자(0000-00-00)
 * @param params endDate - 종료일자(0000-00-00)
 * @param params inventoryCode - 창고코드
 */
export const getInventoryTransferRequests = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: '/api/b1/inventoryTransferRequests',
    responseType: 'blob',
    params: {
      startDate: params.startDate || '',
      endDate: params.endDate || '',
      inventoryCode: params.inventoryCode || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * 납품 실적 조회
 * GET /api/b1/deliveryNotes
 * @param params startDate - 시작일자(0000-00-00)
 * @param params endDate - 종료일자(0000-00-00)
 * @param params bpCode - 고객사코드
 */
export const getDeliveryNotes = async (params: any) => {
  // 결과를 정의함
  let result: any = {};

  await api({
    method: 'GET',
    url: '/api/b1/deliveryNotes',
    responseType: 'blob',
    params: {
      startDate: params.startDate || '',
      endDate: params.endDate || '',
      bpCode: params.bpCode || '',
    },
  })
    .then((response: AxiosResponse<any>) => {
      result = response;
    })
    .catch((error: any) => {});

  return result;
};
