import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import {
  Button,
  Select,
  TextInput,
  Checkbox,
  Radio,
  Progress,
} from '@mantine/core';
import { DatePickerInput, MonthPickerInput } from '@mantine/dates';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import {
  IApiResult,
  IOptionItem,
  ILeftMenu,
  ILoginUser,
  ISelect,
  ITableColumn,
  IPageContent,
  IComponentModal,
  IModal,
  ITablePaging,
} from '../interfaces/app.interface';
import { loginStore } from '../stores/login.store';
import { selectedPageStore } from '../stores/selectedPage.store';
import { modalStore, removeModalStore } from '../stores/modal.store';
import { PageLayout } from '../components/PageLayout';
import { PbFormGrid } from '../components/PbFormGrid';
import { PbFormGridCol } from '../components/PbFormGridCol';
import { pageContentStore } from '../stores/page.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  addComponentModalStore,
  removeComponentModalStore,
} from '../stores/componentModal.store';
import {
  refreshListStore,
  removeRefreshListStore,
} from '../stores/refreshList.store';
import { MN2302220631 } from '../components/MN2302220631'; //등록,상세,수정 Component
import * as b1Api from '../apis/b1.api';
import * as slsApi from '../apis/sls.api';
import { PbAgGridReact } from '../components/PbAgGridReact';
import { decode } from 'html-entities';
import PbSelect from '../components/PbSelect/PbSelect.component';
import { ValueFormatterParams } from 'ag-grid-community';
import * as batchApi from '../apis/batch.api';
import dayjs from 'dayjs';
import * as appUtil from '../utils/app.util';
import { getSlsPrtnSearchBp } from '../apis/sls.api';
import { getDeliveryNotes } from '../apis/b1.api';

/**
 * SAP Tool > 납품실적 조회
 * @constructor
 */
const MN2302220901Page = () => {
  // 언어를 정의함
  const { t } = useTranslation();

  // 로그인한 사용자 저장소를 정의함
  const [loginUser, setLoginUser] = useRecoilState<ILoginUser>(loginStore);

  // 선택한 페이지 저장소를 정의함
  const [selectedPage, setSelectedPage] =
    useRecoilState<ILeftMenu>(selectedPageStore);

  // 페이지 내용 저장소를 정의함
  const [pageContent, setPageContent] =
    useRecoilState<IPageContent>(pageContentStore);

  // 모달 저장소를 정의함
  const [modal, setModal] = useRecoilState<IModal>(modalStore);

  // 삭제할 모달 저장소를 정의함
  const [removeModal, setRemoveModal] = useRecoilState<any>(removeModalStore);

  // 추가할 컴포넌트 모달 저장소를 정의함
  const [addComponentModal, setAddComponentModal] =
    useRecoilState<IComponentModal | null>(addComponentModalStore);

  // 삭제할 컴포넌트 모달 저장소를 정의함
  const [removeComponentModal, setRemoveComponentModal] = useRecoilState<
    string | null
  >(removeComponentModalStore);

  // 검색 폼 그리드 > 조회기간 > 년월일 피커를 정의함
  const [searchDatePicker, setSearchDatePicker] = useState<
    [Date | null, Date | null]
  >([null, null]);

  // 검색 폼 그리드 > 고객사 셀렉트를 정의함
  const [searchCompanySelect, setSearchCompanySelect] = useState<string>('');
  const [searchCompanySelectItem, setSearchCompanySelectItem] = useState<
    IOptionItem[]
  >([]);
  const searchCompanySelectRef = useRef<HTMLInputElement>(null);

  // 검색 폼 그리드 > 조회기간 > 년월일 피커를 변경함
  const handleSearchDatePicker_onChange = (event: any) => {
    setSearchDatePicker(event);
  };

  // 검색 폼 그리드 > 고객사 셀렉트에서 선택한 아이템을 변경함
  const handleSearchCompanySelect_onChange = (event: any) => {
    setSearchCompanySelect(event);
  };

  // 조회하여 엑셀파일로 다운로드 버튼을 클릭함
  const handleSearchButton_onChange = (): void => {
    if (searchCompanySelect === '') {
      setModal({
        title: '알림',
        content: '고객사를 선택하세요.',
      });

      return;
    }

    setModal({
      title: '확인',
      content: '검색 결과를 엑셀 파일로 다운로드 하시겠습니까?',
      useOkayButton: false,
      useCancelButton: true,
      cancelButtonLabel: '아니오',
      button: (
        <>
          {/* 버튼 */}
          <Button
            color="indigo"
            radius="xl"
            size="md"
            onClick={() => {
              b1Api
                .getDeliveryNotes({
                  startDate: dayjs(searchDatePicker[0] || '').format(
                    'YYYY-MM-DD',
                  ),
                  endDate: dayjs(searchDatePicker[1] || '').format(
                    'YYYY-MM-DD',
                  ),
                  bpCode: searchCompanySelect,
                })
                .then((data: any) => {
                  const url = window.URL.createObjectURL(
                    new Blob([data.data], {
                      type: data.headers['content-type'],
                    }),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    ['납품실적_', moment().format('YYYYMMDD'), '.xlsx'].join(
                      '',
                    ),
                  );
                  document.body.appendChild(link);
                  link.click();
                });

              setRemoveModal(true);
            }}
          >
            예
          </Button>
        </>
      ),
    });
  };

  // 한번만 실행함
  useEffect(() => {
    // 오늘은 기준으로 한달 전/후의 날짜를 불러옴
    let tmpStartDate: Date = moment(Date.now()).add(-1, 'months').toDate();
    let tmpEndDate: Date = moment(Date.now()).add(1, 'months').toDate();

    // 검색 폼 그리드 > 조회기간 > 년월일 피커에 적용함
    setSearchDatePicker([tmpStartDate, tmpEndDate]);

    // 고객사 목록을 불러옴
    slsApi
      .getSlsPrtnSearchBp({
        searchType: 'bp_code',
        searchKeyword: '',
      })
      .then((data: IApiResult) => {
        if (data.data.list.length > 0) {
          const selectOptios: { label: string; value: string }[] = [];

          selectOptios.push({
            label: '선택하세요',
            value: '',
          });

          data.data.list.map((item: any, index: number) => {
            selectOptios.push({
              label: `${item.bpName} (${item.cmpnRgstNmbr})`,
              value: item.bpCode,
            });
          });

          // 검색 폼 그리드 > 고객사 셀렉트에 적용함
          setSearchCompanySelectItem(selectOptios);
        }
      });

    return (): void => {
      /* */
    };
  }, []);

  // 조회기간이 변경될 때 실행함
  useEffect((): void => {
    if (searchDatePicker[0] === null || searchDatePicker[1] === null) {
      return;
    }

    let tmpDates = appUtil.getDatesBetween(
      new Date(moment(searchDatePicker[0]).format('YYYY-MM-DD')),
      new Date(moment(searchDatePicker[1]).format('YYYY-MM-DD')),
    );

    // // 조회기간의 일자들에 적용함
    // setRangeDate(tmpDates);
  }, [searchDatePicker]);

  return (
    <PageLayout
      //pageInfoBarLeftArea={<></>}
      pageInfoBarCenterArea={<></>}
      pageInfoBarRightArea={<></>}
      enablePageInfoBarBackgroundColor={true}
    >
      {/* 페이지 내용 */}
      <div className="">
        {/* 검색 */}
        <div className="space-y-5">
          {/* 검색 폼 그리드 */}
          <PbFormGrid label="검색" cols={2}>
            <PbFormGridCol label="조회기간">
              {/* 년월일 피커 */}
              <DatePickerInput
                type="range"
                placeholder="기간을 선택하세요."
                onChange={handleSearchDatePicker_onChange}
                value={searchDatePicker}
                valueFormat="YYYY.MM.DD"
                locale="ko"
                firstDayOfWeek={0}
                clearable={true}
                className="w-full"
              />
            </PbFormGridCol>

            <PbFormGridCol label="고객사">
              {/* 셀렉트 */}
              <Select
                ref={searchCompanySelectRef}
                placeholder="선택하세요."
                onChange={handleSearchCompanySelect_onChange}
                data={searchCompanySelectItem}
                className="w-full"
              />
            </PbFormGridCol>

            <PbFormGridCol label="조회" colSpan={2}>
              {/* 버튼 */}
              <Button
                color="indigo"
                radius="xl"
                onClick={handleSearchButton_onChange}
              >
                조회하여 엑셀파일로 다운로드
              </Button>
            </PbFormGridCol>
          </PbFormGrid>
        </div>
      </div>
    </PageLayout>
  );
};

export default MN2302220901Page;
