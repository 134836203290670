import axios, { AxiosResponse } from 'axios';
import { IApiListResult, IApiResult } from '../interfaces/app.interface';
import api from '../utils/axios.util';

/**
 * BATCH: 배치 관리
 */

/**
 * B1 배치 상태 조회
 * GET /api/batch/status
 *
 */
export const getStatus = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'GET',
    url: '/api/batch/status',
    params,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {});

  return result;
};

/**
 * B1 배치 수동 요청
 * POST /api/batch/{batchCode}
 * @param params batchCode - 배치 요청 서비스명
 */
export const postBatchBatchCode = async (params: any) => {
  // 결과를 정의함
  let result: IApiResult = {
    code: '',
    message: '',
    data: {},
  };

  await api({
    method: 'POST',
    url: ['/api/batch', params.batchCode].join('/'),
    data: params.installPrtnParamVoList,
  })
    .then((response: AxiosResponse<any>) => {
      result = response.data;
    })
    .catch((error: any) => {
      console.log('> postBatchBatchCode Error:', error);
    });

  return result;
};
